import React, { Fragment } from "react";
import Avatar from '@material-ui/core/Avatar';
import withWidth from '@material-ui/core/withWidth'
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import superagent from 'superagent'
import config from 'config'
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/PersonRounded";
import LocalActivityIcon from '@material-ui/icons/LocalActivity'
import NotificationsIcon from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from 'react-router-dom'
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { withUser } from 'stores/KMUserProvider'
import KMCan from "../KMCan/KMCan";

import moment from 'moment-timezone'
import 'moment/min/locales'
moment.locale('fr')
moment.tz.setDefault('Europe/Paris');




class HeaderLinks extends React.Component {
  state = {
    openNotification: false,
    openSearch: false,
    search: '',
    searchResults: [],
    notificationResults: []
  };
  handleToggle = () => {
    this.setState(state => ({ openNotification: !state.openNotification }));
  };
  handleCloseOpenNotification = event => {
    this.setState({ openNotification: false });
  };
  handleCloseOpenSearch = event => {
    this.setState({ openSearch: false });
  };
  handleSearch = event => {
    const target = event.target
    this.setState({ search: target.value }, () => {
      if (target.value.length >= 2) {
        try {
          var evt = new CustomEvent("goAndSearch")
          window.dispatchEvent(evt)
        } catch (e) { console.log(e) }
      }
    })
  }
  componentWillUnmount() {
    try {
      window.removeEventListener("goAndSearch", this.fetchSearch);
    } catch (e) { console.log(e) }
  }
  componentDidMount() {
    window.addEventListener("goAndSearch",
      this.debounce(this.fetchSearch, 300)
    );
    this.fetchNotification()
  }
  fetchNotification = async () => {
    let apiResponseNetwork
    let apiUrl = config.api.notification.url
    try {
      apiResponseNetwork = await superagent
        .get(apiUrl)
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
        .timeout({
          response: 9000, // Wait 9 seconds for the server to start sending,
          deadline: 60000 // but allow 1 minute for the file to finish loading.
        })
    } catch (error) {
      // let message = `erreur réseau X120520191123 ${error}`
      // let variant = 'error'
      // this.props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') {
    } else {
      this.setState({ notificationResults: responseKeepapi.data, openNotification: true })
    }
  }
  debounce = (callback, delay) => {
    // return;
    var timer;
    return function () {
      var args = arguments;
      var context = this;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, delay)
    }
  }

  fetchSearch = async () => {
    if (this.state.search === '') return
    if (this.state.search.length < 3) return
    let apiResponseNetwork
    let apiUrl = config.api.search.url
    try {
      apiResponseNetwork = await superagent
        .post(apiUrl)
        .send({ search: this.state.search })
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
        .timeout({
          response: 9000, // Wait 9 seconds for the server to start sending,
          deadline: 60000 // but allow 1 minute for the file to finish loading.
        })
    } catch (error) {
      let message = `erreur réseau X120520190916 ${error}`
      let variant = 'error'
      this.props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') {
    } else {
      this.setState({ searchResults: responseKeepapi.data, openSearch: true })
    }
  }

  render() {
    const { classes } = this.props;
    const { openNotification, openSearch } = this.state;
    let myWidth = this.props.width === 'sm' || this.props.width === 'xs' ? 'smartphone' : 'large'
    return (
      <div>
        <div className={classes.searchWrapper}>
          <CustomInput

            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            inputProps={{
              placeholder: "Rechercher",
              inputProps: {
                "aria-label": "Search"
              },
              onChange: this.handleSearch,
              value: this.state.search,
              'aria-owns': openSearch ? "search-list-grow" : null,
              'aria-haspopup': "true"
            }}
          />
          <Button
            buttonRef={node => {
              this.searchAnchorEl = node;
            }}
            color="white" aria-label="edit" justIcon round onClick={this.fetchSearch}>
            <Search />
          </Button>
          {this.state.searchResults.length > 0 &&

            <Poppers
              open={openSearch}
              anchorEl={this.searchAnchorEl}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openSearch }) +
                " " +
                classes.pooperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="search-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleCloseOpenSearch}>
                      <MenuList dense role="menu" >

                        {this.state.searchResults.map(searchResult => {
                          if (searchResult.type === "event") {
                            return (
                              <Fragment>
                                <a href={"/event/" + searchResult.eventId}>
                                  <MenuItem
                                    onClick={this.handleCloseOpenNotification}
                                    className={classes.dropdownItem}
                                  >
                                    {searchResult.docPathEstablishment &&
                                      <img src={searchResult.docPathEstablishment} alt='' width={myWidth === 'smartphone' ? 30 : 50} />
                                    }
                                    <b> <LocalActivityIcon />{searchResult.eventLabel} </b>
                                    <br /> Asso : {searchResult.associationLabel}
                                    {searchResult.eventDatetimeStart &&
                                      <Fragment>
                                        <br />Le:  {moment(searchResult.eventDatetimeStart).format("DD/MM/YYYY")}
                                      </Fragment>
                                    }

                                    <br /> Rattaché à {searchResult.establishmentLabel}
                                  </MenuItem>
                                </a>
                              </Fragment>

                            )
                          } else if (searchResult.type === "user") {
                            return (
                              <Fragment>
                                <a href={"/userProfil/" + searchResult.userId}>
                                  <MenuItem
                                    onClick={this.handleCloseOpenNotification}
                                    className={classes.dropdownItem}
                                  >
                                    {searchResult.docPathEstablishment &&
                                      <img alt="" src={searchResult.docPathEstablishment} width={myWidth === 'smartphone' ? 30 : 50} />
                                    }
                                    <b>
                                      {searchResult.docPathAvatar ? (
                                        <Avatar alt={searchResult.userFirstName} src={searchResult.docPathAvatar} style={{ float: "right" }} />
                                      ) : (
                                          <PersonIcon />
                                        )
                                      }
                                      {searchResult.userFirstName} {searchResult.userLastName}</b>
                                    <br /> Tél : {searchResult.userMobilePhone}
                                    <br /> Mail {searchResult.userEmail}
                                  </MenuItem>
                                </a>
                              </Fragment>

                            )
                          } else return (<Fragment />)

                        })
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}


            </Poppers>
          }

        </div>
        <KMCan
          perform='views/Dashboard/Dashboard:visit'
          yes={() => (
            <Link to={"/dashboard"}>
              <Button
                color={window.innerWidth > 959 ? "transparent" : "white"}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-label="Dashboard"
                className={classes.buttonLink}
              >
                <Dashboard className={classes.icons} />
                <Hidden mdUp implementation="css">
                  <div className={classes.linkText}>Tableau de bord</div>
                </Hidden>
              </Button>
            </Link>
          )}
        />
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openNotification ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.fetchNotification}
            className={classes.buttonLink}
          >
            <NotificationsIcon className={classes.icons} />
            {this.state.notificationResults.length > 0 &&
              <span className={classes.notifications}>{this.state.notificationResults.length}</span>
            }
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={openNotification}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openNotification }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleCloseOpenNotification}>
                    <MenuList role="menu">
                      {this.state.notificationResults.map(notificationResult => {
                        if (notificationResult.type === "eventToCheck") {
                          return (
                            <a key={"eventToCheck_" + notificationResult.eventId} href={"/event/" + notificationResult.eventId}>
                              <MenuItem
                                onClick={this.handleCloseOpenNotification}
                                className={classes.dropdownItem}
                              ><LocalActivityIcon /> {notificationResult.eventLabel} à valider
                                </MenuItem>
                            </a>
                          )
                        }
                        else if (notificationResult.type === "userWaitingForAccreditation") {
                          return (
                            <a key={"userWaitingForAccreditation_" + notificationResult.userId} href={"/userProfil/" + notificationResult.userId}>

                              <MenuItem
                                onClick={this.handleCloseOpenNotification}
                                className={classes.dropdownItem}
                              ><PersonIcon /> {notificationResult.userFirstName} {notificationResult.userLastName} {notificationResult.userEmail} en attente d'accréditation
                                </MenuItem>
                            </a>
                          )
                        }
                        return null
                      })
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        <Link to={"/userProfil/" + this.props.user.id}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="PersonIcon"
            className={classes.buttonLink}
          >
            <PersonIcon className={classes.icons} />
            <Hidden mdUp implementation="css">
              <div className={classes.linkText}>Profile</div>
            </Hidden>
          </Button>
        </Link>
        {/* <App/> */}
      </div>
    );
  }
}

export default withWidth()(withStyles(headerLinksStyle)(withSnackbar(withUser(HeaderLinks))))
