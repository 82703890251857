import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";
import { FavoriteRounded, FitnessCenterRounded, AcUnitRounded } from "@material-ui/icons";
import config from 'config'

function Footer({ ...props }) {
  const { classes } = props;
  let slogan = null
  const second = new Date().getUTCSeconds()
  if (second <= 20) {
    slogan = (
      <span>
        &copy; {1900 + new Date().getYear()}{" "}
        <a href="https://keepapi.com/webAgency" className={classes.a}>
          Keepapi
      </a>, made with <FavoriteRounded fontSize="small" /> for a better web
      </span>
    )
  }
  else if (second > 20 && second <= 25) {
    slogan = (
      <span>
        &copy; {1900 + new Date().getYear()}{" "}
        <a href="https://keepapi.com/webAgency" className={classes.a}>
          Keepapi
</a>, <FitnessCenterRounded fontSize="small" /> dev team
</span>
    )

  } else {
    slogan = (
      <span>
        &copy; {1900 + new Date().getYear()}{" "}
        <a href="https://keepapi.com/webAgency" className={classes.a}>
          Keepapi
</a>, french tech  <AcUnitRounded fontSize="small" />  lille
</span>
    )
  }

  return (

    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.right}>
          {slogan} {config.version}
        </div>
      </div>
    </footer>
  );
}

export default withStyles(footerStyle)(Footer)
