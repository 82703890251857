import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMAssociationContext = createContext({
  associationStore: {}, // store all the association details
  updateAssociationStore: () => { },
  initAssociationStore: () => { },
  reloadAssociationStore: () => { },
  associationStoreRightPermissionDenied: true
})

export class KMAssociationProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      associationStore: {}, // store all the association details
      updateAssociationStore: this.updateAssociationStore,
      initAssociationStore: this.initAssociationStore,
      reloadAssociationStore: this.reloadAssociationStore,
      associationStoreRightPermissionDenied: true
    }
    this.updateAssociationStore = this.updateAssociationStore.bind(this)
    this.initAssociationStore = this.initAssociationStore.bind(this)
    this.reloadAssociationStore = this.reloadAssociationStore.bind(this)
  }
  reloadAssociationStore = (key, value) => {
    this.initAssociationStore(this.state.associationStore.associationId)
  }
  updateAssociationStore = (key, value) => {
    let myAssociationStore = this.state.associationStore // faster deep copy 
    myAssociationStore[key] = value
    this.setState({ associationStore: myAssociationStore })
    // let associationStore = { ...this.state.associationStore }
    // associationStore[key] = value
    // this.setState({ associationStore })
  }
  initAssociationStore = async (associationId) => {
    let apirUrl = config.api.associationDetail.url
    apirUrl = apirUrl.replace('___ASSOCIATIONID___', associationId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let associationStore = responseKeepapi.data[0]
      this.setState({ associationStore, associationStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMAssociationContext.Provider value={this.state}>
        {this.props.children}
      </KMAssociationContext.Provider>
    )
  }
}

export const withAssociationStore = Component => props => (
  <KMAssociationContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMAssociationContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withAssociation } from 'stores/KMAssociationProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.association.firstName}!</h1>)
  }
}
export default withAssociation(TOTO)

 */
