import React, { createContext, Component } from 'react'
import config from 'config.js'

import superagent from 'superagent'

export const KMUserContext = createContext({
  user: {} // store all the user details
})

export class KMUserProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {} // store all the user details
    }
  }
  componentDidMount() {
    this.fetchUser()
  }
  async fetchUser() {
    let apiUserUrl = config.api.userSession.url
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiUserUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) { return }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') return
    this.setState({ user: responseKeepapi.data[0] })
  }

  render() {
    return (
      <KMUserContext.Provider value={this.state}>
        {this.props.children}
      </KMUserContext.Provider>
    )
  }
}
// H O C
export const withUser = Component => props => (
  <KMUserContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMUserContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withUser } from './stores/KMUserProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.user.firstName}!</h1>)
  }
}
export default withUser(TOTO)

 */
