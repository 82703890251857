import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMEventContext = createContext({
  eventStore: {}, // store all the event details
  updateEventStore: () => { },
  initEventStore: () => { },
  reloadEventStore: () => { },
  eventStoreRightPermissionDenied: true
})

export class KMEventProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventStore: {}, // store all the event details
      updateEventStore: this.updateEventStore,
      initEventStore: this.initEventStore,
      reloadEventStore: this.reloadEventStore,
      eventStoreRightPermissionDenied: true
    }
    this.updateEventStore = this.updateEventStore.bind(this)
    this.initEventStore = this.initEventStore.bind(this)
    this.reloadEventStore = this.reloadEventStore.bind(this)
  }
  reloadEventStore = (key, value) => {
    this.initEventStore(this.state.eventStore.eventId)
  }
  updateEventStore = (key, value) => {
    let myEventStore = this.state.eventStore // faster deep copy 
    myEventStore[key] = value
    this.setState({ eventStore: myEventStore })
    // let eventStore = { ...this.state.eventStore }
    // eventStore[key] = value
    // this.setState({ eventStore })
  }
  initEventStore = async (eventId) => {
    let apirUrl = config.api.eventDetail.url
    apirUrl = apirUrl.replace('___EVENTID___', eventId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let eventStore = responseKeepapi.data[0]
      this.setState({ eventStore, eventStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMEventContext.Provider value={this.state}>
        {this.props.children}
      </KMEventContext.Provider>
    )
  }
}

export const withEventStore = Component => props => (
  <KMEventContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMEventContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withEvent } from 'stores/KMEventProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.event.firstName}!</h1>)
  }
}
export default withEvent(TOTO)

 */
