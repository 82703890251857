import React from 'react';
import classNames from "classnames";
import { ListItemText, ListItemAvatar, Input, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import customInputStyle from "assets/jss/material-kit-react/components/customInputStyle.jsx";
import config from 'config'
import superagent from 'superagent'

class KMEstablishment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            establishmentId: '',
            establishments: []
        }
    }
    componentDidMount() {
        this.fetchEstablishment()
    }
    async fetchEstablishment() {
        let apiClientUrl = config.front.establishment.url
        apiClientUrl += '?sort=establishmentLabel_asc'
        let apiResponseNetwork
        try {
            apiResponseNetwork = await superagent
                .get(apiClientUrl)
                .set('Content-Type', 'application/json')
                .set('Accept', 'application/json')
                // .set('X-JWT', window.localStorage.getItem('JWT'))
                .withCredentials()
        } catch (error) { return }
        let responseKeepapi = apiResponseNetwork.body
        if (responseKeepapi.code !== 'ok') return
        this.setState({ establishments: responseKeepapi.data })
        if (responseKeepapi.data.length > 0) {
            if (!!this.props.flagEmptyOption === true) {
                this.setState({ establishmentId: '' })
                this.props.handleMaterialChange(this.props.id, '');
            } else {
                this.setState({ establishmentId: responseKeepapi.data[0].establishmentId })
                this.props.handleMaterialChange(this.props.id, responseKeepapi.data[0].establishmentId);
            }
        }
    }
    handleChange = (event, index, value) => {
        this.setState({ establishmentId: event.target.value });
        this.props.handleMaterialChange(this.props.id, event.target.value);
    }
    render() {
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            white,
            inputRootCustomClasses,
            success,
            flagEmptyOption = false
        } = this.props;
        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error
        });
        const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true,
            [classes.whiteUnderline]: white
        });
        const marginTop = classNames({
            [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });
        const inputClasses = classNames({
            [classes.input]: true,
            [classes.whiteInput]: white
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
                formControlProps.className,
                classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }
        let rowsMenuItems = this.state.establishments.map(
            (establishment, position) => {
                return (
                    <MenuItem className={classes.menuItem} key={"MenuItem_" + position} value={establishment.establishmentId}>
                        <ListItemAvatar >
                            <img src={establishment.establishmentLogo} width="125" style={{ margin: "20px" }} alt="" />
                        </ListItemAvatar>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={establishment.establishmentFullLabel} />
                    </MenuItem>
                )
            }
        )
        if (!!flagEmptyOption === true) {

            rowsMenuItems.unshift(
                (
                    <MenuItem className={classes.menuItem} key={"MenuItem_flagEmptyOption"  } value={''}>
                        <ListItemAvatar >

                        </ListItemAvatar>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={"Choisir un établissement dans la liste"} />
                    </MenuItem>
                )
            )
        }
        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : null}
                <Select
                    onChange={this.handleChange}
                    classes={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,

                    }}
                    id={id}
                    input={<Input classes={{
                        underline: underlineClasses
                    }} />}

                    {...inputProps}
                    value={this.state.establishmentId}
                >
                    {rowsMenuItems}
                </Select>
            </FormControl>

        );
    }
}

export default withStyles(customInputStyle)(KMEstablishment);