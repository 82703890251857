import superagent from 'superagent'
import config from 'config'

import { withSnackbar } from 'notistack';
import KMPopupForgotPassword from './KMPopupForgotPassword'
import React from 'react'

import Reflux from 'reflux'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { InputAdornment, IconButton } from '@material-ui/core'
// @material-ui/icons
import { EmailRounded, LockRounded, VisibilityRounded } from '@material-ui/icons'
// core components
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Button from 'components/KMLogin/CustomButtons/Button.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import CardBody from 'components/KMLogin/Card/CardBody.jsx'
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import CardFooter from 'components/KMLogin/Card/CardFooter.jsx'
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'
import MessageActions from 'reflux/message_actions'

import loginFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'

let dico = require('../../lang/front.fr.js')

class LoginForm extends Reflux.Component {
  constructor(props) {
    super(props)
    // this._isMounted = false
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      flagPasswordVisible: false,
      openForgotPasswordPopup: false,
      cardAnimaton: 'cardHidden',
      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      forms: {
        email: {
          errorText: '',
          errorTextMessage: dico.login.error.email,
          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          hintText: dico.login.email,
          value: '',
          label: dico.login.email,
          id: 'email',
          success: false
        },
        password: {
          errorText: '',
          errorTextMessage: dico.login.error.password,
          pattern: /.{5,}/,
          hintText: dico.login.password,
          value: '',
          label: dico.login.password,
          id: 'password',
          success: false
        }
      }
    }
  }
  handleFlagPasswordVisible = () => this.setState({ flagPasswordVisible: !this.state.flagPasswordVisible })

  handleInputChangeMaterial = (event) => {
    const target = event.target
    let id = event.target.id
    let value = target.type === 'checkbox' ? target.checked : target.value
    let forms = { ...this.state.forms }
    // prepare value to set
    forms[id].value = value
    this.setState(prevState => ({
      forms: forms
    }))
  }
  customCheckField(field) {
    let { errorText, error, name } = this.checkField([field])
    const forms = { ...this.state.forms }
    forms[name].errorText = errorText
    forms[name].success = !error
    this.setState({ forms: forms })
    return !error
  }
  checkField(fields) {
    let error = false
    let errorText = ''
    let value = ''
    const forms = { ...this.state.forms }
    let name

    fields.map((currentName) => {
      if (!!error === true) { return currentName }
      name = currentName
      if (
        forms[currentName].hasOwnProperty('pattern') &&
        forms[currentName].hasOwnProperty('errorText') &&
        forms[currentName].hasOwnProperty('errorTextMessage') &&
        forms[currentName].errorTextMessage !== ''
      ) {
        value = forms[currentName].value
        if (!value.match(forms[currentName].pattern)) {
          error = true
          errorText = forms[currentName].errorTextMessage
        } else {
          errorText = ''
        }
      }
      return currentName
    })

    return { error, errorText, name }
  }




  handleLogIn = async (event) => {
    if (event) event.preventDefault()
    const components = { ...this.state.components }
    components.KMMaterialWaiting.displayWaiting = true
    this.setState({ components: components })
    let error = true
    error = error & this.customCheckField('email')
    error = error & this.customCheckField('password')
    if (!!error !== false) {
      try {
        caches.keys().then(function (names) {
          for (let name of names) {
            caches.delete(name)
            console.log('caches.delete', name)
            // alert('caches.delete')
          }
        })
        navigator.serviceWorker.getRegistrations()
          .then(registrations => {
            for (let registration of registrations) {
              registration.unregister().then((flag) => console.log('unregister', flag, registration))
            }
          })
      } catch (error) { console.log("try to delete cache failed") }
      function removeCookie(name, path, domain) {
        let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        document.cookie = str
      }
      removeCookie("kmSession", '/', `.${config.mainDomainName}`)
      window.localStorage.removeItem('JWT')
      const oauthLoginUrl = config.oauth.login.url
      let loggedIn = false
      try {
        let env = await fetch(oauthLoginUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value })
        })
        let { code, data } = await env.json()
        if (code === 'ok') {
          if (data.auth === true && data.token !== '') {
            window.localStorage.setItem('JWT', data.token)
            loggedIn = true
          }
        }
      } catch (error) {
        console.log(error)
        loggedIn = false
      }
      if (loggedIn === true) {
        this.props.openPrivateRoute()
      } else {
        MessageActions.show(dico.login.error.oauth.not_found)
      }
    }
    components.KMMaterialWaiting.displayWaiting = false
    this.setState({ components: components })
  }



  handleLogInSSO = async (event) => {
    const components = { ...this.state.components }
    components.KMMaterialWaiting.displayWaiting = true
    this.setState({ components: components })
    try {
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name)
          console.log('caches.delete', name)
          // alert('caches.delete')
        }
      })
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for (let registration of registrations) {
            registration.unregister().then((flag) => console.log('unregister', flag, registration))
          } 
        })
    } catch (error) { console.log("try to delete cache failed") }
    function removeCookie(name, path, domain) {
      let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
      document.cookie = str
    }
    removeCookie("kmSession", '/', `.${config.mainDomainName}`)
    window.localStorage.removeItem('JWT')
    const oauthLoginUrl = config.oauth.login.url + '/saml/callback'
    let loggedIn = false
    try {
      
      let env = await fetch(oauthLoginUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        method: 'get',
        // body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value })
      })
      
      let leFormulaireAPoster = await env.text()
      document.getElementById('root').innerHTML = leFormulaireAPoster
      let form = document.getElementsByTagName('form')[0]
      form.method="get"
      form.submit()

      // debugger
      // let { code, data } = await env.json()
      // if (code === 'ok') {
      //   if (data.auth === true && data.token !== '') {
      //     window.localStorage.setItem('JWT', data.token)
      //     loggedIn = true
      //   }
      // }
    } catch (error) {
      console.log(error)
      loggedIn = false
    }
    if (loggedIn === true) {
      this.props.openPrivateRoute()
    } else {
      MessageActions.show(dico.login.error.oauth.not_found)
    }
    components.KMMaterialWaiting.displayWaiting = false
    this.setState({ components: components })
  }

  componentDidMount() {
    // this._isMounted = true

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        // if (this._isMounted) {
        this.setState({ cardAnimaton: '' })
        // }
      }.bind(this),
      450
    )
  }
  // componentWillUnmount() {
  //   this._isMounted = false
  // }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[this.state.cardAnimaton]}>
              <form className={classes.form} >
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{dico.login.title2}</h4>
                  <div className={classes.socialLine}>
                    <Button
                      color="white"
                      onClick={this.props.handleSwitchView}
                    >
                      {dico.login.subscribeNow}&nbsp;&nbsp; <LockRounded fontSize="small" />
                    </Button>
                    {/* <Button
justIcon
href="#pablo"
target="_blank"
color="transparent"
onClick={e => e.preventDefault()}
>
<i className={"fa fa-twitter"} />
</Button>
<Button
justIcon
href="#pablo"
target="_blank"
color="transparent"
onClick={e => e.preventDefault()}
>
<i className={"fa fa-facebook"} />
</Button>
<Button
justIcon
href="#pablo"
target="_blank"
color="transparent"
onClick={e => e.preventDefault()}
>
<i className={"fa fa-google"} />
</Button> */}
                  </div>
                </CardHeader>
                <p className={classes.divider}>{dico.login.title1}</p>
                <CardBody>
                  {/* <CustomInput
labelText={dico.login.firstName}
id="first"
formControlProps={{
fullWidth: true
}}
inputProps={{
type: "text",
endAdornment: (
<InputAdornment position="end">
<People className={classes.inputIconsColor} />
</InputAdornment>
)
}}
/> */}

                  <KMCustomInput
                    labelText={(this.state.forms.email.errorText === '') ? this.state.forms.email.label : this.state.forms.email.errorText}
                    error={this.state.forms.email.errorText !== ''}
                    success={this.state.forms.email.success}
                    id={this.state.forms.email.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.password.errorText === '') ? this.state.forms.password.label : this.state.forms.password.errorText}
                    error={this.state.forms.password.errorText !== ''}
                    success={this.state.forms.password.success}
                    id={this.state.forms.password.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    handleSubmit={this.handleLogIn}
                    inputProps={{
                      type: !!this.state.flagPasswordVisible === true ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end" onClick={this.handleFlagPasswordVisible} >
                          <IconButton style={{ padding: 0 }}>
                            {!!this.state.flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />



                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button color="primary" size="lg" onClick={this.handleLogIn}>
                    {dico.login.loginAction}
                  </Button>
                  {/* <Button color="primary" size="lg" onClick={this.handleLogInSSO}>
                    {dico.login.loginActionSSO}
                  </Button> */}

                </CardFooter>
                <CardFooter className={classes.cardFooter}>
                  <KMPopupForgotPassword
                    handleOK={this.handleOK}
                    handleClose={this.handleCloseForgotPasswordPopup}
                    open={this.state.openForgotPasswordPopup} />

                  <Button simple color="primary" size="sm" onClick={this.handleOpenForgotPasswordPopup}>{dico.login.forgotPassword}</Button>

                </CardFooter>

              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

    )
  }
  handleOpenForgotPasswordPopup = () => this.setState({ openForgotPasswordPopup: true })
  handleCloseForgotPasswordPopup = () => this.setState({ openForgotPasswordPopup: false })
  handleOK = async (userEmail) => {
    this.setState({ openForgotPasswordPopup: false })
    if (userEmail === '') {
      let message = `Email vide`
      let variant = 'warning'
      this.props.enqueueSnackbar(message, { variant })
      return
    }
    let apiResponseNetwork
    let apiUrl = config.webhook.forgotPasswordUser.url
    try {
      apiResponseNetwork = await superagent
        .post(apiUrl)
        .send({ userEmail })
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) {
      let message = `erreur réseau X180520191825 ${error}`
      let variant = 'error'
      this.props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    let message, variant
    if (responseKeepapi.code !== 'ok') {
      message = `une erreur est survenue`
      variant = 'error'
    } else {
      message = `Un mail vous a été transmis sur votre mail. Un lien dans ce message vous permettra de réinitialiser votre mot de passe`
      variant = 'success'
    }
    this.props.enqueueSnackbar(message, { variant })
  }
}

export default withStyles(loginFormStyle)(withSnackbar(LoginForm))
