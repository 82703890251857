import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMResourceContext = createContext({
  resourceStore: {}, // store all the resource details
  updateResourceStore: () => { },
  initResourceStore: () => { },
  reloadResourceStore: () => { },
  resourceStoreRightPermissionDenied: true
})

export class KMResourceProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resourceStore: {}, // store all the resource details
      updateResourceStore: this.updateResourceStore,
      initResourceStore: this.initResourceStore,
      reloadResourceStore: this.reloadResourceStore,
      resourceStoreRightPermissionDenied: true
    }
    this.updateResourceStore = this.updateResourceStore.bind(this)
    this.initResourceStore = this.initResourceStore.bind(this)
    this.reloadResourceStore = this.reloadResourceStore.bind(this)
  }
  reloadResourceStore = (key, value) => {
    this.initResourceStore(this.state.resourceStore.resourceId)
  }
  updateResourceStore = (key, value) => {
    let myResourceStore = this.state.resourceStore // faster deep copy 
    myResourceStore[key] = value
    this.setState({ resourceStore: myResourceStore })
    // let resourceStore = { ...this.state.resourceStore }
    // resourceStore[key] = value
    // this.setState({ resourceStore })
  }
  initResourceStore = async (resourceId) => {
    let apirUrl = config.api.resourceDetail.url
    apirUrl = apirUrl.replace('___RESOURCEID___', resourceId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let resourceStore = responseKeepapi.data[0]
      this.setState({ resourceStore, resourceStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMResourceContext.Provider value={this.state}>
        {this.props.children}
      </KMResourceContext.Provider>
    )
  }
}

export const withResourceStore = Component => props => (
  <KMResourceContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMResourceContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withResource } from 'stores/KMResourceProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.resource.firstName}!</h1>)
  }
}
export default withResource(TOTO)

 */
