
import Reflux from 'reflux';
import MessageActions from './message_actions';


class MessageStore extends Reflux.Store{
	// ==================================================================
	// liveCycle ========================================================
	// ==================================================================
	constructor(){
		super();
		this.onClose = this.onClose.bind(this)
		this.state = {
			open:false,
			message:"",
			autoHideDuration:4000,
			onClose:this.onClose,
			onActionTouchTapMessage:null,
			action:null
		}; 
		
        this.listenables = MessageActions;
	}
	onClose (){
		
		this.setState({
			open: false,
		});
	}
	// ==================================================================
	// callback ========================================================
	// ==================================================================

	onShowOK(message,action,autoHideDuration,onActionTouchTapMessage){
		this.setState({
			 open: true,
		 });
		 this.setState({
			 message: message,
		 });
		 this.setState({
			 action: action,
		 });
		 this.setState({
			 autoHideDuration: autoHideDuration,
		 });
		 this.setState({
			 onActionTouchTapMessage: onActionTouchTapMessage,
		 });
    }
};

export default MessageStore;