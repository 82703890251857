import React from 'react';
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '../../components/KMLogin/CustomButtons/Button.jsx'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ThumbUpIcon from '@material-ui/icons/ThumbUpRounded';
import { withStyles } from '@material-ui/core/styles';
import { withEventStore } from 'stores/KMEventProvider'
import { Email } from '@material-ui/icons'

import loginFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'
class KMPopupForgotPassword extends React.Component {
    state = {
        email: ''
    };

    // set state forms
    handleInputChangeForm = (event) => {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        this.setState({ email: value })
    }
    handleOK = () => {
        this.props.handleOK(this.state.email);
        this.setState({ email: '' })
    };
    render() {
        if (!!this.props.open !== true) return null
        const {
            classes,
        } = this.props;

        // const labelClasses = classNames({
        //     [" " + classes.labelRootError]: error,
        //     [" " + classes.labelRootSuccess]: success && !error
        // });
        // const underlineClasses = classNames({
        //     [classes.underlineError]: error,
        //     [classes.underlineSuccess]: success && !error,
        //     [classes.underline]: true
        // });
        // const marginTop = classNames({
        //     [classes.marginTop]: labelText === undefined
        // });

        return (
            <Dialog
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Mot de passe oublié ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <b>Entrez votre email pour nous aider à vous authentifier.</b>
                    </DialogContentText>
                    {/* <TextField
                        multiline={false}
                        autoFocus
                        margin="dense"
                        label="Entrez votre email"
                        type="text"
                        fullWidth
                        value={this.state.email}
                        onChange={this.handleInputChangeForm}
                        classes={{
                            root: marginTop,
                            disabled: classes.disabled,
                            underline: underlineClasses
                        }}
                    /> */}
                    <KMCustomInput
                        autoFocus
                        labelText="Entrez votre email"
                        handleMaterialChange={this.handleInputChangeForm}

                        formControlProps={{
                            fullWidth: true
                        }}

                        handleSubmit={this.handleOK}
                        
                        inputProps={{
                            type: 'email',
                            endAdornment: (
                                <InputAdornment position="end">
                                <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                            )
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button  onClick={this.props.handleClose} size="sm"  color="white">
                        Fermer la pop-in
            </Button>

                    {/* <Button
                        onClick={this.handleOK}

                        variant="outlined" color="primary" className={classes.button}> */}
                    <Button color="primary" size="sm" onClick={this.handleOK}>
                        <ThumbUpIcon className={classes.leftIcon} />Renvoyez moi un mon mot de passe
            </Button>
                </DialogActions>
            </Dialog>
        )
    };
}
export default withStyles(loginFormStyle)(withEventStore(KMPopupForgotPassword))

