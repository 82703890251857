import clsx from 'clsx'
import React, { useState, Fragment } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
import Avatar from '@material-ui/core/Avatar'
import { RibbonContainer, LeftCornerRibbon } from "react-ribbons";

// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx'

import sidebarStyle from '../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx'
import config from 'config'
import superagent from 'superagent'
import { withUser } from 'stores/KMUserProvider'
import KMCan from 'components/KMCan/KMCan'
import { withClient } from 'stores/KMClientProvider'
import { PersonRounded, ExitToAppRounded } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'

const Sidebar = ({ ...props }) => {
  const [openUser, setOpenUser] = useState(false)

  const handleLogOut = async (event) => {
    const oauthLogoutUrl = config.oauth.logout.url
    try {
      await superagent
        .get(oauthLogoutUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (e) { }
    // let responseKeepapi = apiResponseNetwork.body
    // if (responseKeepapi.code !== 'ok') return
    try {
      function removeCookie(name, path, domain) {
        let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        document.cookie = str
      }
      removeCookie("kmSession", '/', `.${config.mainDomainName}`);
    } catch (e) { }
    window.localStorage.clear()
    window.location.href = `https://${config.mainDomainName}`
  }
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return routeName && routeName.test(props.location.pathname) === true
    // return props.location.pathname.indexOf(routeName) > -1
  }
  const { classes, color, client, image, logoText, routes, user = {} } = props

  var links = (

    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null
        var activePro = ' '
        var listItemClasses

        listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.pathPattern),
          [' ' + classes.listItemClassesFullWidth]: props.isContainerFullWidth && activeRoute(prop.pathPattern)
        })
        // [itemLinkFullWidth]:props.isContainerFullWidth
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.pathPattern)
        })
        return (
          <KMCan
            key={"links_" + key}
            perform={prop.rbac || 'null'}
            yes={() => (
              <NavLink
                to={prop.path.replace(/:userId/, props.user.id)}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                    {typeof prop.icon === 'string' ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                        <prop.icon />
                      )}
                  </ListItemIcon>

                  <ListItemText
                    primary={props.isContainerFullWidth !== true ? prop.sidebarName : <Fragment>&nbsp;</Fragment>}
                    className={classes.itemText + whiteFontClasses}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            )} />
        )
      })}
    </List>
  )
  const brand =
    config.ribbonMessage ? (
      <div className={classes.logoRibbon} >
        <RibbonContainer >
          <LeftCornerRibbon backgroundColor="#ab47bc" color="#f0f0f0" fontFamily="Arial">
            <b>{config.ribbonMessage}</b>
          </LeftCornerRibbon>
          <a href="" className={classes.logoLink}>
            <div className={clsx({ [classes.logoImage]: true, [classes.logoImageFullWidth]: props.isContainerFullWidth })}>
              {/* <img src={logo} alt="logo" className={classes.img} /> */}
              {client.kmClientLogo &&
                <img src={client.kmClientLogo} alt={client.kmClientLogo} width={props.isContainerFullWidth ? 125 : 160} />
              }
            </div>
            {logoText}
          </a>

        </RibbonContainer>
      </div >
    ) : (
        <div className={classes.logo} >

          <a href="" className={classes.logoLink}>
            <div className={clsx({ [classes.logoImage]: true, [classes.logoImageFullWidth]: props.isContainerFullWidth })}>
              {/* <img src={logo} alt="logo" className={classes.img} /> */}
              {client.kmClientLogo &&
                <img src={client.kmClientLogo} alt={client.kmClientLogo} width={props.isContainerFullWidth ? 125 : 160} />
              }
            </div>
            {logoText}
          </a>

        </div >
      )

  let userFirstAndLastName = `${user.firstName || ''
    } ${user.lastName || ''} `

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            <div className={classes.user}>
              <ListItem button onClick={() => setOpenUser(!openUser)}>
                {user.hasOwnProperty('avatarDocData') === true &&
                  <ListItemAvatar>
                    <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                      <Avatar alt={user.firstName} src={user.avatarDocData} className={classes.avatar} />
                    </Tooltip>
                  </ListItemAvatar>
                }
                {user.hasOwnProperty('avatarDocData') === false &&
                  <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                    <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                      <PersonRounded />
                    </Tooltip>
                  </ListItemIcon >
                }
                <ListItemText primary={userFirstAndLastName}
                  className={classes.itemText + ' ' + classes.whiteFont}
                  disableTypography={true} />
                {openUser ? <ExpandLess className={classes.itemText + ' ' + classes.whiteFont} /> : <ExpandMore className={classes.itemText + ' ' + classes.whiteFont} />}
              </ListItem >
              <Collapse in={openUser} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button >
                    <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                      <ExitToAppRounded className={classes.itemText + ' ' + classes.whiteFont} />
                    </ListItemIcon>
                    <ListItemText primary={'Déconnexion'}
                      onClick={handleLogOut}
                      className={classes.itemText + ' ' + classes.whiteFont}
                      disableTypography={true} />                </ListItem>
                </List>
              </Collapse>
            </div >

            {links}
          </div >
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer >
      </Hidden >
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}

          // className={clsx(classes.drawer, {
          //   [classes.drawerOpen]: openUser,
          //   [classes.drawerClose]: !openUser,
          // })}
          classes={{
            paper: clsx({
              [classes.drawerPaper]: true,
              [classes.drawerOpen]: !props.isContainerFullWidth,
              [classes.drawerClose]: props.isContainerFullWidth,
            }),
          }}
        >
          {brand}
          <div className={classes.user}>
            <ListItem button onClick={() => setOpenUser(!openUser)} >
              {user.hasOwnProperty('avatarDocData') === true &&
                <ListItemAvatar className={clsx({ [classes.listItemFullWidth]: props.isContainerFullWidth })}>
                  <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>

                    <Avatar alt={user.firstName} src={user.avatarDocData} className={classes.avatar} />
                  </Tooltip>

                </ListItemAvatar>
              }
              {user.hasOwnProperty('avatarDocData') === false &&
                <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                  {/* <Icon alt={user.firstName} title={`connecté avec l'adresse ${user.email} ${user.aclCode}`} className={'fa fa-user'} color="primary" /> */}
                  <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                    <PersonRounded />
                  </Tooltip>
                </ListItemIcon>
              }
              {props.isContainerFullWidth===false &&
              <ListItemText primary={userFirstAndLastName}
                className={classes.itemText + ' ' + classes.whiteFont}
                disableTypography={true} />
              }
              {openUser ? <ExpandLess className={classes.itemText + ' ' + classes.whiteFont} /> : <ExpandMore className={classes.itemText + ' ' + classes.whiteFont} />}
            </ListItem>
            <Collapse in={openUser} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                    <ExitToAppRounded />
                  </ListItemIcon>
                  <ListItemText primary={'Déconnexion'}
                    onClick={handleLogOut}
                    className={classes.itemText + ' ' + classes.whiteFont}
                    disableTypography={true} />                </ListItem>
              </List>
            </Collapse>
          </div>

          <div className={classes.sidebarWrapper}>{links}</div>

          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div >
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(sidebarStyle)(withUser(withClient(Sidebar)))
