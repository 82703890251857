import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMMessageContext = createContext({
  messageStore: {}, // store all the message details
  updateMessageStore: () => { },
  initMessageStore: () => { },
  reloadMessageStore: () => { },
  messageStoreRightPermissionDenied: true
})

export class KMMessageProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageStore: {}, // store all the message details
      updateMessageStore: this.updateMessageStore,
      initMessageStore: this.initMessageStore,
      reloadMessageStore: this.reloadMessageStore,
      messageStoreRightPermissionDenied: true
    }
    this.updateMessageStore = this.updateMessageStore.bind(this)
    this.initMessageStore = this.initMessageStore.bind(this)
    this.reloadMessageStore = this.reloadMessageStore.bind(this)
  }
  reloadMessageStore = (key, value) => {
    this.initMessageStore(this.state.messageStore.messageId)
  }
  updateMessageStore = (key, value) => {
    let myMessageStore = this.state.messageStore // faster deep copy 
    myMessageStore[key] = value
    this.setState({ messageStore: myMessageStore })
    // let messageStore = { ...this.state.messageStore }
    // messageStore[key] = value
    // this.setState({ messageStore })
  }
  initMessageStore = async (messageId) => {
    let apirUrl = config.api.messageDetail.url
    apirUrl = apirUrl.replace('___MESSAGEID___', messageId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let messageStore = responseKeepapi.data[0]
      this.setState({ messageStore, messageStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMMessageContext.Provider value={this.state}>
        {this.props.children}
      </KMMessageContext.Provider>
    )
  }
}

export const withMessageStore = Component => props => (
  <KMMessageContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMMessageContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withMessage } from 'stores/KMMessageProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.message.firstName}!</h1>)
  }
}
export default withMessage(TOTO)

 */
