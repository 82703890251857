
const styles = {
  
  keepapi: {
    fontWeight: 300,
    fontSize: 21,
    lineHeight: '35px',
    marginRight: '10px',
    color: '#000000',
    textAlign:'center',
  },
  golden: {
    color: '#BFA056'
  }
};

export default styles;
