import React from 'react'

import config from 'config'
import Reflux from 'reflux'


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { InputAdornment, IconButton } from '@material-ui/core'
// @material-ui/icons
import { EmailRounded, PeopleRounded, SchoolRounded, LockRounded, VisibilityRounded } from '@material-ui/icons'
// core components
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Button from 'components/KMLogin/CustomButtons/Button.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import CardBody from 'components/KMLogin/Card/CardBody.jsx'
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import CardFooter from 'components/KMLogin/Card/CardFooter.jsx'
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'
import MessageActions from 'reflux/message_actions'
import KMEstablishment from '../../components/KMLogin/KMCustomInput/KMEstablishment'

import registerFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'

let dico = require('../../lang/front.fr.js')

class RegisterForm extends Reflux.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      flagPasswordVisible: false,

      cardAnimaton: 'cardHidden',
      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      forms:
      {
        email: {
          errorText: '',
          errorTextMessage: dico.login.error.email,
          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          hintText: dico.login.email,
          value: '',
          label: dico.login.email,
          id: 'email',
          success: false
        },
        password: {
          errorText: '',
          errorTextMessage: dico.login.error.password,
          // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.+~{[|\\^\]}%!@#$%^&*;,_])(?=.{5,})/,
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.+~{[|\\^\]}%!@#$%^&*;,_])(?=.{5,})/,
          hintText: dico.login.password,
          value: '',
          label: dico.login.password,
          id: 'password',
          success: false
        },
        firstName: {
          errorText: '',
          errorTextMessage: dico.login.error.firstName,
          pattern: /.{1,}/,
          hintText: dico.login.firstName,
          value: '',
          label: dico.login.firstName,
          id: 'firstName',
          success: false
        },
        lastName: {
          errorText: '',
          errorTextMessage: dico.login.error.lastName,
          pattern: /.{2,}/,
          hintText: dico.login.lastName,
          value: '',
          label: dico.login.lastName,
          id: 'lastName',
          success: false
        },
        establishmentId: {
          errorText: '',
          errorTextMessage: dico.login.error.establishmentId,
          pattern: /[0-9]{1,}/,
          hintText: dico.login.establishmentId,
          value: '',
          label: dico.login.establishmentId,
          id: 'establishmentId',
          success: false
        }
      }

    }

  }
  handleFlagPasswordVisible = () => this.setState({ flagPasswordVisible: !this.state.flagPasswordVisible })

  handleInputChangeMaterial = (event) => {
    const target = event.target
    let id = event.target.id
    let value = target.type === 'checkbox' ? target.checked : target.value
    let forms = { ...this.state.forms }
    // prepare value to set
    forms[id].value = value
    this.setState(prevState => ({
      forms: forms
    }))
  }
  handleSelectChangeMaterial = (name, value) => {
    let forms = { ...this.state.forms }
    // prepare value to set
    forms[name].value = value
    this.setState(prevState => ({
      forms: forms
    }))
  }
  customCheckField(field) {
    let { errorText, error, name } = this.checkField([field])
    const forms = { ...this.state.forms }
    forms[name].errorText = errorText
    forms[name].success = !error
    this.setState({ forms: forms })
    return !error
  }
  checkField(fields) {
    let error = false
    let errorText = ''
    let value = ''
    const forms = { ...this.state.forms }
    let name

    fields.map((currentName) => {
      if (!!error === true) { return currentName }
      name = currentName
      if (
        forms[currentName].hasOwnProperty('pattern') &&
        forms[currentName].hasOwnProperty('errorText') &&
        forms[currentName].hasOwnProperty('errorTextMessage') &&
        forms[currentName].errorTextMessage !== ''
      ) {
        value = forms[currentName].value
        if (value.match === undefined)
          value = value + ""
        if (!value.match(forms[currentName].pattern)) {
          error = true
          errorText = forms[currentName].errorTextMessage
        } else {
          errorText = ''
        }
      }
      return currentName
    })

    return { error, errorText, name }
  }

  handleRegister = async (event) => {
    this.props.displayWaitingOn()
    let error = true
    error = error & this.customCheckField('establishmentId')
    error = error & this.customCheckField('email')
    error = error & this.customCheckField('password')
    error = error & this.customCheckField('firstName')
    error = error & this.customCheckField('lastName')
    if (!!error !== false) {
      const oauthRegisterUrl = config.oauth.register.url
      let loggedIn = false
      let message = dico.login.error.oauth.notRegister

      try {
        let env = await fetch(oauthRegisterUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value, firstName: this.state.forms.firstName.value, lastName: this.state.forms.lastName.value, establishmentId: this.state.forms.establishmentId.value })
        })
        let { code, data } = await env.json()
        message = data.message
        if (code === 'ok') {
          if (data.auth === true && data.token !== '') {
            window.localStorage.setItem('JWT', data.token)
            loggedIn = true
          }
        }
      } catch (error) {
        console.log(error)
        loggedIn = false
      }
      if (loggedIn === true) {
        this.props.openPrivateRoute()
      } else {
        MessageActions.show(message)
      }
    }
    this.props.displayWaitingOff()
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      450
    )
  }
  render() {
    const { classes } = this.props
    return (

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[this.state.cardAnimaton]}>
              <form className={classes.form}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault()
                    this.handleRegister()
                  }
                }}>

                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{dico.login.title1}</h4>
                  <div className={classes.socialLine}>
                    <Button
                      color="white"
                      onClick={this.props.handleSwitchView}
                    >
                      {dico.login.connectNow}&nbsp;&nbsp; <LockRounded fontSize="small" />
                    </Button>
                  </div>
                </CardHeader>
                <p className={classes.divider}>{dico.login.fillAllBlanks}</p>
                <CardBody>
                  <KMCustomInput
                    labelText={(this.state.forms.firstName.errorText === '') ? this.state.forms.firstName.label : this.state.forms.firstName.errorText}
                    error={this.state.forms.firstName.errorText !== ''}
                    success={this.state.forms.firstName.success}
                    id={this.state.forms.firstName.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <PeopleRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.lastName.errorText === '') ? this.state.forms.lastName.label : this.state.forms.lastName.errorText}
                    error={this.state.forms.lastName.errorText !== ''}
                    success={this.state.forms.lastName.success}
                    id={this.state.forms.lastName.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <PeopleRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />

                  <KMEstablishment
                    flagEmptyOption={true}
                    labelText={(this.state.forms.establishmentId.errorText === '') ? this.state.forms.establishmentId.label : this.state.forms.establishmentId.errorText}
                    error={this.state.forms.establishmentId.errorText !== ''}
                    success={this.state.forms.establishmentId.success}
                    id={this.state.forms.establishmentId.id}
                    handleMaterialChange={this.handleSelectChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      name: this.state.forms.establishmentId.id,
                      endAdornment: (
                        <InputAdornment position="end">
                          <SchoolRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />

                  <KMCustomInput
                    labelText={(this.state.forms.email.errorText === '') ? this.state.forms.email.label : this.state.forms.email.errorText}
                    error={this.state.forms.email.errorText !== ''}
                    success={this.state.forms.email.success}
                    id={this.state.forms.email.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.password.errorText === '') ? this.state.forms.password.label : this.state.forms.password.errorText}
                    error={this.state.forms.password.errorText !== ''}
                    success={this.state.forms.password.success}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    id={this.state.forms.password.id}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: !!this.state.flagPasswordVisible === true ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end" onClick={this.handleFlagPasswordVisible} >
                          <IconButton style={{ padding: 0 }}>
                            {!!this.state.flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.state.forms.password.errorText !== '' &&
                    <div>{dico.login.error.passwordStrength}</div>
                  }

                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button color="primary" size="lg" onClick={this.handleRegister}>
                    {dico.login.registerAction}
                  </Button>

                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

    )
  }
}

export default withStyles(registerFormStyle)(RegisterForm)
