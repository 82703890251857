import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { withSnackbar } from 'notistack';
import Quote from "components/Typography/Quote.jsx";
import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { CastForEducationRounded } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import superagent from 'superagent'
import config from 'config.js'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  paper: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`
  },

  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: '#00acc1'
  },
}));


function KMBanner(props) {
  const classes = useStyles();
  const [messagePlateformUnreads, setMessagePlateformUnreads] = useState([])

  const fetchMessagePlateformUnread = async (eventId) => {
    let apiResponseNetwork
    let apiUrl = config.api.messagePlateformUnread.url
    try {
      apiResponseNetwork = await superagent
        .get(apiUrl)
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) {
      let message = `erreur réseau lors de la récupération des derniers messages sur la plateforme ${error}`
      let variant = 'error'
      props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body

    if (responseKeepapi.code === 'ok') {
      setMessagePlateformUnreads(responseKeepapi.data)
    }
  }
  const handleMessageRead = async (messageId) => {
    let apiResponseNetwork
    let apiUrl = config.webhook.messagePlateformRead.url
    try {
      apiResponseNetwork = await superagent
        .post(apiUrl)
        .send({ messageId: messageId })
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
        .timeout({
          response: 9000, // Wait 9 seconds for the server to start sending,
          deadline: 60000 // but allow 1 minute for the file to finish loading.
        })
    } catch (error) {
      let message = `erreur réseau lors de la mise à jour du message${error}`
      let variant = 'error'
      props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    let message, variant

    if (responseKeepapi.code === 'ok') {
      message = `Le message est marqué comme étant lu`
      variant = 'success'
      fetchMessagePlateformUnread()
    } else {
      message = `erreur lors de la lecture du message `
      variant = 'warning'
    }
    props.enqueueSnackbar(message, { variant })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessagePlateformUnread()
    }, 5*60000);
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    fetchMessagePlateformUnread()
  }, [])

  if (messagePlateformUnreads.length === 0) return null

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.paper}>
        <AutoPlaySwipeableViews
          interval={10000}
          enableMouseEvents
        >
          {messagePlateformUnreads.map(messagePlateformUnread =>
            <Grid container wrap="nowrap" justify="space-between" alignItems="center">
              <Grid item>


                <Quote
                  text={<Fragment>{messagePlateformUnread.messageLabel} <small dangerouslySetInnerHTML={{ __html: messagePlateformUnread.messageDetail }} /></Fragment>}
                  author=" La direction des moyens généraux"
                ></Quote>

              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" onClick={() => handleMessageRead(messagePlateformUnread.messageId)}>OK</Button>
              </Grid>
            </Grid>
          )}

        </AutoPlaySwipeableViews>
      </Paper>
      <Divider />
    </React.Fragment>
  );
}

export default withSnackbar(KMBanner);