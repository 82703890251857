import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMLocationContext = createContext({
  locationStore: {}, // store all the location details
  locationStoreExtraData: {},
  updateLocationStore: () => { },
  initLocationStore: () => { },
  reloadLocationStore: () => { },
  locationStoreRightPermissionDenied: true
})

export class KMLocationProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locationStore: {}, // store all the location details
      updateLocationStore: this.updateLocationStore,
      initLocationStore: this.initLocationStore,
      reloadLocationStore: this.reloadLocationStore,
      locationStoreRightPermissionDenied: true
    }
    this.updateLocationStore = this.updateLocationStore.bind(this)
    this.initLocationStore = this.initLocationStore.bind(this)
    this.reloadLocationStore = this.reloadLocationStore.bind(this)
  }
  reloadLocationStore = (key, value) => {
    this.initLocationStore(this.state.locationStore.locationId)
  }
  updateLocationStore = (key, value) => {
    let myLocationStore = this.state.locationStore // faster deep copy 
    myLocationStore[key] = value
    this.setState({ locationStore: myLocationStore })
  }
  initLocationStore = async (locationId) => {
    let apirUrl = config.api.locationDetail.url
    apirUrl = apirUrl.replace('___LOCATIONID___', locationId)
    let apiResponseNetwork

    try {

      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let locationStore = responseKeepapi.data[0]
      locationStore.extraData = 'extraData' in responseKeepapi ? responseKeepapi.extraData : {}
      this.setState({ locationStore, locationStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMLocationContext.Provider value={this.state}>
        {this.props.children}
      </KMLocationContext.Provider>
    )
  }
}

export const withLocationStore = Component => props => (
  <KMLocationContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMLocationContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withLocation } from 'stores/KMLocationProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.location.firstName}!</h1>)
  }
}
export default withLocation(TOTO)

 */
