import React from 'react'
import Pace from 'react-pace/vendor/pace/pace.js'
// import 'react-pace/vendor/pace/themes/black/pace-theme-barber-shop.css'
// import 'assets/css/pace.css'

class KMProgressBar extends React.Component {

    componentDidMount() {
        try {
            Pace.start()
        }
        catch (error) {
            alert(error)
        }
    }

    render() {
        return (null)
    }
}

export default KMProgressBar

