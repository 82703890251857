import Reflux from 'reflux'

var MessageActions = Reflux.createActions({  
  "show":{children: ['OK']}
});


MessageActions.show.listen( function(message="", action=null, autoHideDuration=4000) {
  this.OK(message, action, autoHideDuration);
});


export default MessageActions;
