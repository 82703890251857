import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMReportContext = createContext({
  reportStore: {}, // store all the report details
  updateReportStore: () => { },
  updateReportStoreAndRefresh: () => { },
  initReportStore: () => { },
  reloadReportStore: () => { },
  reportStoreRightPermissionDenied: true
})

export class KMReportProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportStore: {
        fieldCriteriaActifs: [], // for mockup front remove thi sline when backend is ready pleaz!
        fieldCriteriaGroups: [], // for mockup front remove thi sline when backend is ready pleaz!
        fieldCriterias: [],// for mockup front remove thi sline when backend is ready pleaz!
        filterCriteriaActifs: [], // for mockup front remove thi sline when backend is ready pleaz!
        filterCriteriaGroups: [], // for mockup front remove thi sline when backend is ready pleaz!
        filterCriterias: [],// for mockup front remove thi sline when backend is ready pleaz!
      }, // store all the report details
      updateReportStore: this.updateReportStore,
      updateReportStoreAndRefresh: this.updateReportStoreAndRefresh,
      initReportStore: this.initReportStore,
      reloadReportStore: this.reloadReportStore,
      reportStoreRightPermissionDenied: true
    }
    this.updateReportStore = this.updateReportStore.bind(this)
    this.updateReportStoreAndRefresh = this.updateReportStoreAndRefresh.bind(this)
    this.initReportStore = this.initReportStore.bind(this)
    this.reloadReportStore = this.reloadReportStore.bind(this)
  }
  reloadReportStore = (key, value) => {
    this.initReportStore(this.state.reportStore.reportId)
  }
  updateReportStore = (key, value) => {
    let myReportStore = this.state.reportStore // faster deep copy 
    myReportStore[key] = value
    this.setState({ reportStore: myReportStore })
    // let reportStore = { ...this.state.reportStore }
    // reportStore[key] = value
    // this.setState({ reportStore })
  }
  updateReportStoreAndRefresh = (key, value) => {
    this.updateReportStore(key, value)
    this.updateReportStore('refreshToggle', !!!this.state.reportStore.refreshToggle)
  }
  initReportStore = async (reportId) => {
    let apirUrl = config.report.detail.url
    apirUrl = apirUrl.replace('___REPORTID___', reportId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let reportStore = responseKeepapi.data[0]
      this.setState({ reportStore, reportStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMReportContext.Provider value={this.state}>
        {this.props.children}
      </KMReportContext.Provider>
    )
  }
}

export const withReportStore = Component => props => (
  <KMReportContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMReportContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withReport } from 'stores/KMReportProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.report.firstName}!</h1>)
  }
}
export default withReport(TOTO)

 */
