import React, { lazy } from 'react'
// @material-ui/icons
import { SchoolRounded, EventSeatRounded, LocalActivity, Dashboard, PersonRounded, PanToolRounded, InfoRounded, PinDropRounded, HomeWorkRounded, CastForEducationRounded, ScheduleRounded, AssessmentRounded } from '@material-ui/icons'
// core components/views
const KMProfilDetailView = lazy(() => import('views/KMProfilDetailView/KMProfilDetailView'));
const KMUserManagementView = lazy(() => import('views/KMUserManagementView/KMUserManagementView'));

const DashboardPage = lazy(() => import('views/Dashboard/Dashboard.jsx'));
const KMListEventView = lazy(() => import('views/KMListEventView/KMListEventView'));
const KmEventDetailView = lazy(() => import('views/KmEventDetailView/KmEventDetailView'));
const KMListEstablishmentView = lazy(() => import('views/KMListEstablishmentView/KMListEstablishmentView'));
const KMEstablishmentDetailView = lazy(() => import('views/KMEstablishmentDetailView/KMEstablishmentDetailView'));
const KMResourceListView = lazy(() => import('views/KMResourceListView/KMResourceListView'));

const KMResourceDetailView = lazy(() => import('views/KMResourceDetailView/KMResourceDetailView'));
const KMMessageListView = lazy(() => import('views/KMMessageListView/KMMessageListView'));

const KMMessageDetailView = lazy(() => import('views/KMMessageDetailView/KMMessageDetailView'));

const KMAssociationListView = lazy(() => import('views/KMAssociationListView/KMAssociationListView'));
const KMAssociationDetailView = lazy(() => import('views/KMAssociationDetailView/KMAssociationDetailView'));

const KMProductListView = lazy(() => import('views/KMProductListView/KMProductListView'));
const KMProductDetailView = lazy(() => import('views/KMProductDetailView/KMProductDetailView'));



const KMLocationView = lazy(() => import('views/KMLocationView/KMLocationView'));

const KMScheduledBroadcastListView = lazy(() => import('views/KMScheduledBroadcastListView/KMScheduledBroadcastListView'));
const KMScheduledBroadcastDetailView = lazy(() => import('views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView'));
const KMReportListView = lazy(() => import('views/KMReportListView/KMReportListView'));
const KMReportDetailView = lazy(() => import('views/KMReportDetailView/KMReportDetailView'));


const dashboardRoutes = [



  {
    path: '/dashboard',
    sidebarName: 'Tableau de bord',
    navbarName: <div><Dashboard />Tableau de bord</div>,
    icon: Dashboard,
    component: DashboardPage,
    rbac: 'views/Dashboard/Dashboard:visit',
    pathPattern: /\/dashboard/
  },
  {
    path: '/events',
    sidebarName: 'Événements',
    navbarName: <div><LocalActivity />Liste des événements</div>,
    icon: LocalActivity,
    component: KMListEventView,
    rbac: 'views/KMListEventView/KMListEventView:visit',
    pathPattern: /^\/events$|^\/event\/[0-9]+$/
  },
  {
    path: '/event/:eventId',
    sidebarName: '',
    navbarName: <div><LocalActivity />Événement</div>,
    icon: LocalActivity,
    component: KmEventDetailView,
    rbac: 'views/KmEventDetailView/KmEventDetailView:visit',
    pathPattern: /\/event\/[0-9]+/
  },
  {
    path: '/reports',
    sidebarName: 'Rapports',
    navbarName: <div><AssessmentRounded />Liste des rapports</div>,
    icon: AssessmentRounded,
    component: KMReportListView,
    rbac: 'views/KMReportListView/KMReportListView:visit',
    pathPattern: /^\/reports$|^\/report\/[0-9]+$/
  },
  {
    path: '/report/:reportId',
    sidebarName: '',
    navbarName: <div><AssessmentRounded />Rapport</div>,
    icon: AssessmentRounded,
    component: KMReportDetailView,
    rbac: 'views/KMReportDetailView/KMReportDetailView:visit',
    pathPattern: /\/report\/[0-9]+/
  },
  {
    path: '/userProfil/:userId',
    sidebarName: 'Votre compte',
    navbarName: <div><PersonRounded />Votre compte</div>,
    icon: PersonRounded,
    component: KMProfilDetailView,
    rbac: 'views/UserProfile/UserProfile:visit',
    pathPattern: /\/userProfil\/[0-9]+/
  },
  {
    path: '/resources',
    sidebarName: 'Ressources',
    navbarName: <div><InfoRounded />Ressources</div>,
    icon: InfoRounded,
    component: KMResourceListView,
    rbac: 'views/KMResourceListView/KMResourceListView:visit',
    pathPattern: /\/resources/
  },
  {
    path: '/resource/:resourceId',
    sidebarName: '',
    navbarName: <div><InfoRounded />Ressource</div>,
    icon: InfoRounded,
    component: KMResourceDetailView,
    rbac: 'views/KMResourceDetailView/KMResourceDetailView:visit',
    pathPattern: /\/resource\/[0-9]+/
  },
  {
    path: '/messages',
    sidebarName: 'Messages',
    navbarName: <div><CastForEducationRounded />Messages</div>,
    icon: CastForEducationRounded,
    component: KMMessageListView,
    rbac: 'views/KMMessageListView/KMMessageListView:visit',
    pathPattern: /\/messages/
  },
  {
    path: '/message/:messageId',
    sidebarName: '',
    navbarName: <div><CastForEducationRounded />Message</div>,
    icon: CastForEducationRounded,
    component: KMMessageDetailView,
    rbac: 'views/KMMessageDetailView/KMMessageDetailView:visit',
    pathPattern: /\/message\/[0-9]+/
  },
  {
    path: '/associations',
    sidebarName: 'Associations',
    navbarName: <div><SchoolRounded />Associations</div>,
    icon: SchoolRounded,
    component: KMAssociationListView,
    rbac: 'views/KMAssociationListView/KMAssociationListView:visit',
    pathPattern: /\/associations/
  },
  {
    path: '/association/:associationId',
    sidebarName: '',
    navbarName: <div><SchoolRounded />Association</div>,
    icon: SchoolRounded,
    component: KMAssociationDetailView,
    rbac: 'views/KMAssociationDetailView/KMAssociationDetailView:visit',
    pathPattern: /\/association\/[0-9]+/
  },


  

  {
    path: '/establishments',
    sidebarName: 'Établissements',
    navbarName: <div><HomeWorkRounded />Liste des établissements</div>,
    icon: HomeWorkRounded,
    component: KMListEstablishmentView,
    rbac: 'views/KMListEstablishmentView/KMListEstablishmentView:visit',
    pathPattern: /^\/establishments$|^\/establishment\/[0-9]+$/

  },
  {
    path: '/establishment/:establishmentId',
    sidebarName: '',
    navbarName: <div><HomeWorkRounded />Établissement</div>,
    icon: HomeWorkRounded,
    component: KMEstablishmentDetailView,
    rbac: 'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
    pathPattern: /\/establishment\/[0-9]+/
  },

  {
    path: '/user',
    sidebarName: 'Droits',
    navbarName: <div><PanToolRounded />Droits</div>,
    icon: PanToolRounded,
    component: KMUserManagementView,
    rbac: 'views/KMUserManagementView/KMUserManagementView:visit',
    pathPattern: /^\/user$/
  },

  {
    path: '/scheduledBroadcasts',
    sidebarName: 'Messages planifiés',
    navbarName: <div><ScheduleRounded />Liste des messages planifiés</div>,
    icon: ScheduleRounded,
    component: KMScheduledBroadcastListView,
    rbac: 'views/KMScheduledBroadcastListView/KMScheduledBroadcastListView:visit',
    pathPattern: /^\/scheduledBroadcasts$|^\/scheduledBroadcast\/[0-9]+$/

  },
  {
    path: '/scheduledBroadcast/:scheduledBroadcastId',
    sidebarName: '',
    navbarName: <div><ScheduleRounded />Message planifié</div>,
    icon: ScheduleRounded,
    component: KMScheduledBroadcastDetailView,
    rbac: 'views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView:visit',
    pathPattern: /\/scheduledBroadcast\/[0-9]+/
  },



  // {
  //   path: '/eventsOld',
  //   sidebarName: 'Événements',
  //   navbarName: 'Liste des événements',
  //   icon: Unarchive,
  //   component: KmEventDetailView,
  //   rbac:'views/eventsOld/eventsOld:visit'
  // },
  // {
  //   path: '/table',
  //   sidebarName: 'Pôle logistique',
  //   navbarName: 'Pôle Logistique',
  //   icon: 'content_paste',
  //   component: TableList,
  //   rbac: 'demo',
  //   pathPattern: /\/table/

  // },
  // {
  //   path: '/typography',
  //   sidebarName: 'Pôle Multimédia',
  //   navbarName: 'Pôle Multimédia',
  //   icon: LibraryBooks,
  //   component: Typography,
  //   rbac: 'demo',
  //   pathPattern: /\/typography/
  // },
  // {
  //   path: '/icons',
  //   sidebarName: 'Pôle technique',
  //   navbarName: 'Pôle technique',
  //   icon: BubbleChart,
  //   component: Icons,
  //   rbac: 'demo',
  //   pathPattern: /\/icons/
  // },
  // {
  //   path: '/maps',
  //   sidebarName: '', /* "Maps" */
  //   navbarName: '', /* "Map" */
  //   icon: '', /* LocationON, */
  //   component: Maps,
  //   rbac: 'demo',
  //   pathPattern: /\/maps/
  // },
  // {
  //   path: '/notifications',
  //   sidebarName: 'Notifications',
  //   navbarName: 'Notifications',
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   rbac: 'views/NotificationsPage/NotificationsPage:visit',
  //   pathPattern: /\/notifications/
  // },
  // // {
  // //   path: '/upgrade-to-pro',
  // //   sidebarName: 'Upgrade To PRO',
  // //   navbarName: 'Upgrade To PRO',
  // //   icon: Unarchive,
  // //   component: UpgradeToPro
  // // },
  // {
  //   path: '/ApiList',
  //   sidebarName: 'APIs provisoires',
  //   navbarName: 'Liste des APIs provisoires',
  //   icon: Unarchive,
  //   component: ApiListView,
  //   rbac: 'views/ApiListView/ApiListView:visit',
  //   pathPattern: /\/ApiList/
  // },

  {
    path: '/locations',
    sidebarName: 'Emplacements',
    navbarName: <div><PinDropRounded />Emplacements</div>,
    icon: PinDropRounded,
    component: KMLocationView,
    rbac: 'views/KMLocationView/KMLocationView:visit',
    pathPattern: /\/locations/
  },
  {
    path: '/location/:locationIdParam',
    sidebarName: '',
    navbarName: <div><PinDropRounded />Emplacements</div>,
    icon: PinDropRounded,
    component: KMLocationView,
    rbac: 'views/KMLocationView/KMLocationView:visit',
    pathPattern: /\/location\/[0-9]+$/
  },
  {
    path: '/products',
    sidebarName: 'Articles',
    navbarName: <div><EventSeatRounded />Articles</div>,
    icon: EventSeatRounded,
    component: KMProductListView,
    rbac: 'views/KMProductListView/KMProductListView:visit',
    pathPattern: /\/products/
  },
  {
    path: '/product/:productId',
    sidebarName: '',
    navbarName: <div><EventSeatRounded />Article</div>,
    icon: EventSeatRounded,
    component: KMProductDetailView,
    rbac: 'views/KMProductDetailView/KMProductDetailView:visit',
    pathPattern: /\/product\/[0-9]+/
  },
  { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
]

export default dashboardRoutes
