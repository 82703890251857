import React from 'react'

import workInProgressImg from 'assets/img/workInProgress.png'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core/'

import styles from './styles'

const WorkInProgressView = ({
    classes,
    ...props
}) => (

        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <img src={workInProgressImg} alt="work in progress by keepapi team"/>
            </Grid>
            <Grid item>
                <div className={classes.keepapi}>
                    La plateforme est en maintenance pour quelques minutes ...
                    <br/>
                    Merci de votre compréhension.
                    <br/>
                    <b className={classes.golden}>L'équipe {'{'} Keep : Api {'}'}</b>
                </div>
            </Grid>

        </Grid>
    )


export default withStyles(styles)(WorkInProgressView)
