import React, { Component } from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardAvatar from 'components/Card/CardAvatar.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Email from '@material-ui/icons/Email'
import { withUser } from 'stores/KMUserProvider'

import config from 'config'
import superagent from 'superagent'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
}

export class KmCompleteInscription extends Component {
  constructor (props) {
    super(props)
    this.state = {
      imgs: [],
      imgNb: null
    }
  }
  async fetchEstablishment() {
    let apiClientUrl = config.api.establishmentPictures.url
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiClientUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) { return }
    let responseKeepapi = apiResponseNetwork.body
    console.log(responseKeepapi)
    if (responseKeepapi.code !== 'ok') return
    if (responseKeepapi.data.length > 0) {
      this.setState({ imgs: responseKeepapi.data.map((e) => {return e.establishmentLogo}) })
      this.setState({imgNb: this.mutableRotate(this.state.imgs)[0]})
    }
  }
  mutableRotate (arr, direction = 'left') {
    direction === 'left' ? arr.push(arr.shift()) : arr.unshift(arr.pop())
    return arr
  }
  componentDidMount () {
    this.fetchEstablishment()
    this.myVar = setInterval(() => this.setState({imgNb: this.mutableRotate(this.state.imgs)[0]}), 3000)
  }
  componentWillUnmount () {
    clearInterval(this.myVar)
  }
  render () {
    const { classes } = this.props

    return (
      <div>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <Card profile>
              <CardAvatar >
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img height="200" src={this.state.imgNb} alt="..." /> 
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Bienvenue! </h6>
                <h4 className={classes.cardTitle}><Email></Email> Etape 1. Merci de complétez votre inscription</h4>
                <p className={classes.description}>
                Un mail de confirmation vous a été envoyé à l'adresse suivante : {this.props.user.email}. Veuillez consulter votre messagerie pour valider votre compte.
                </p>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(styles)(withUser(KmCompleteInscription))
