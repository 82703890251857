import _ from 'lodash';


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  close: {
    padding: theme.spacing(1) / 2,
  },
});

class KMMaterialSnackBar extends React.Component {


// 	constructor(props, context) {
//     super(props, context);
  
//   this.handleClose = this.handleClose.bind(this)
// }
// handleClose(event, index, value) {
//   this.props.handleClose(event, index, value);
// }

  render() {
    let tempProps = _.extend(this.props)
    tempProps = _.omit(tempProps, ['handleMaterialChange']);
    const { classes } = this.props;
    return (
      <div>
        
        <Snackbar
          message={tempProps.message}
          autoHideDuration={tempProps.autoHideDuration}
          open={tempProps.open}
          // action={tempProps.action}
          onClose={tempProps.onClose}
          action={[
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={tempProps.onClose}
              >
                <CloseIcon />
                </IconButton>
          ]}

          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'left',
          // }}
          // {...tempProps}  
          // ContentProps={{
          //   'aria-describedby': 'message-id',
          // }}
          
          // action={[
          //   <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
          //     UNDO
          //   </Button>,
          //   <IconButton
          //     key="close"
          //     aria-label="Close"
          //     color="inherit"
          //     className={classes.close}
          //     onClick={this.handleClose}
          //   >
          //     <CloseIcon />
          //   </IconButton>,
          // ]}
        />
      </div>
    );
  }
}

KMMaterialSnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KMMaterialSnackBar);




