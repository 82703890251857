import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMProductContext = createContext({
  productStore: {}, // store all the product details
  updateProductStore: () => { },
  initProductStore: () => { },
  reloadProductStore: () => { },
  productStoreRightPermissionDenied: true
})

export class KMProductProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productStore: {}, // store all the product details
      updateProductStore: this.updateProductStore,
      initProductStore: this.initProductStore,
      reloadProductStore: this.reloadProductStore,
      productStoreRightPermissionDenied: true
    }
    this.updateProductStore = this.updateProductStore.bind(this)
    this.initProductStore = this.initProductStore.bind(this)
    this.reloadProductStore = this.reloadProductStore.bind(this)
  }
  reloadProductStore = (key, value) => {
    this.initProductStore(this.state.productStore.productId)
  }
  updateProductStore = (key, value) => {
    let myProductStore = this.state.productStore // faster deep copy 
    myProductStore[key] = value
    this.setState({ productStore: myProductStore })
    // let productStore = { ...this.state.productStore }
    // productStore[key] = value
    // this.setState({ productStore })
  }
  initProductStore = async (productId) => {
    let apirUrl = config.api.productDetail.url
    apirUrl = apirUrl.replace('___PRODUCTID___', productId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let productStore = responseKeepapi.data[0]
      this.setState({ productStore, productStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMProductContext.Provider value={this.state}>
        {this.props.children}
      </KMProductContext.Provider>
    )
  }
}

export const withProductStore = Component => props => (
  <KMProductContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMProductContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withProduct } from 'stores/KMProductProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.product.firstName}!</h1>)
  }
}
export default withProduct(TOTO)

 */
