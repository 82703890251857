import clsx from 'clsx'
import { withClient } from 'stores/KMClientProvider'
import React, { Suspense } from 'react'
import KMProgressBar from 'components/KMProgressBar/KMProgressBar'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
// creates a beautiful scrollbar
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import {withWidth} from '@material-ui/core'
// core components
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import dashboardRoutes from 'routes/dashboard.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'
import image from 'assets/img/sidebar-2.jpg'
// import logo from "assets/img/reactlogo.png";
import KMMaterialWaiting from 'components/KMLogin/KMMaterial/KMMaterialWaiting'
import KmCompleteInscription from 'views/KmCompleteInscription/KmCompleteInscription'
import KmWaitingForAccreditation from 'views/KmWaitingForAccreditation/KmWaitingForAccreditation'
import { KMCanChecker } from 'components/KMCan/KMCan'
import KMCan from 'components/KMCan/KMCan'
import { withUser } from 'stores/KMUserProvider'
const switchRoutes = (user) => (
  <Suspense fallback={<div></div>}>
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        const canView = KMCanChecker({
          user: user.user,
          perform: prop.rbac
        })
        if (prop.redirect) {
          if (user.userStateCode === 'KM_SUPERVISOR') {
            return <Redirect from={prop.path} to={prop.to} key={key} />
          }
          else {
            return <Redirect from={prop.path} to={"/events"} key={key} />
          }
        }
        if (!!canView === false) return null
        return <Route path={prop.path} component={prop.component} key={key} />
      })}
    </Switch>
  </Suspense>
)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false
    }
    this.resizeFunction = this.resizeFunction.bind(this)

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };
  isContainerFullWidth() {
    let {width} = this.props

    return !!this.props.location.pathname.match(/\/report\/[0-9]+/) && width !== 'sm' && width !== 'xs'
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }
  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   const ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    window.addEventListener('resize', this.resizeFunction)
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      // this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction)
  }
  render() {
    const { classes, ...rest } = this.props
    const isContainerFullWidth = this.isContainerFullWidth()

    if (typeof this.props.user === 'object') { // aka user is authenticated
      let textNoActifUser
      if (this.props.user.userStateCode === 'H_USER_NEW') {
        textNoActifUser = <KmCompleteInscription />

      }
      else if (this.props.user.userStateCode === 'WF_USER_SENT_CONFIRM_EMAIL') {
        textNoActifUser = <KmCompleteInscription />

      }
      else if (this.props.user.userStateCode === 'H_USER_CONFIRMED_EMAIL') {
        textNoActifUser = <KmWaitingForAccreditation />

      }
      else if (this.props.user.userStateCode === 'WF_USER_WAITING_FOR_ACCREDITATION') {
        textNoActifUser = <KmWaitingForAccreditation />

      }
      else {
        textNoActifUser = <KMMaterialWaiting
          display={true}
          disconnect={true}
        />

      }

      const dashboardRoutesForSidebar = dashboardRoutes.filter(route => route.sidebarName !== '')
      return (

        <KMCan
          perform="layout/Dashboard/Dashboard:visit"
          yes={() => (
            <div className={classes.wrapper}>
              <Sidebar
                isContainerFullWidth={isContainerFullWidth}
                routes={dashboardRoutesForSidebar}
                logoText={''}
                logo={this.props.client.kmClientLogo}
                image={image}
                handleDrawerToggle={this.handleDrawerToggle}
                open={this.state.mobileOpen}
                color="blue"
                {...rest}
              />
              <div className={
                clsx({
                  [classes.mainPanel]: true,
                  [classes.mainPanelFullWidth]: isContainerFullWidth,
                })
               } >
                <KMProgressBar />
                <Header
                  routes={dashboardRoutes}
                  handleDrawerToggle={this.handleDrawerToggle}
                  {...rest}
                />
                {isContainerFullWidth === false ? (
                  <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes(this.props)}</div>
                  </div>
                ) : (
                    <div style={{  flexGrow: '1 !important', width:'auto'}}>{switchRoutes(this.props)}</div>
                  )}
                <Footer />
              </div>
            </div>

          )}
          no={() =>
            <div className={classes.wrapper}>
              <Sidebar
                isContainerFullWidth={isContainerFullWidth}
                routes={[]}
                logoText={''}
                logo={this.props.client.kmClientLogo}
                image={image}
                handleDrawerToggle={this.handleDrawerToggle}
                open={this.state.mobileOpen}
                color="blue"
                {...rest}
              />
              <div className={classes.mainPanel} >
                <div className={classes.content}>
                  <div className={classes.container}>
                    <div>
                      {textNoActifUser}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>

          }
        />
      )
    }
    return (
      <div className={classes.wrapper}>
        <Sidebar
          isContainerFullWidth={isContainerFullWidth}
          user={this.props.user}
          routes={[]}
          logoText={''}
          logo={this.props.client.kmClientLogo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            routes={[]}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <KMMaterialWaiting
                display={true}
                disconnect={true}
              />
            </div>
          </div>

          <Footer />

        </div>
      </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withWidth()(withStyles(dashboardStyle)(withUser(withClient(App))))
