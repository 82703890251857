
import { withClient } from 'stores/KMClientProvider'
import React from 'react'
import _ from 'lodash'
import Reflux from 'reflux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
// core components
import Header from 'components/KMLogin/Header/Header'
import HeaderLinks from 'components/KMLogin/Header/HeaderLinks'
import Footer from 'components/KMLogin/Footer/Footer'
import KMMaterialWaiting from 'components/KMLogin/KMMaterial/KMMaterialWaiting'
import KMMaterialSnackBar from 'components/KMLogin/KMMaterial/KMMaterialSnackBar'
import MessageStore from 'reflux/message_stores'

import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage'

import image from 'assets/img/bg7.jpg'
import RegisterForm from './RegisterForm'
import ResetPasswordForm from './ResetPasswordForm'

import LoginForm from './LoginForm'
import CookieConsent from "react-cookie-consent";



class LoginPage extends Reflux.Component {
  constructor (props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {

      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      token:''
    }
    this.stores = [ MessageStore ]

    this.handleLoginView = this.handleLoginView.bind(this)

    this.handleRegisterView = this.handleRegisterView.bind(this)

    this.displayWaitingOn = this.displayWaitingOn.bind(this)
    this.displayWaitingOff = this.displayWaitingOff.bind(this)
  }

  displayWaitingOn (event) {
    const components = _.extend(this.state.components)
    components.KMMaterialWaiting.displayWaiting = true
    this.setState({components: components})
  }
  displayWaitingOff (event) {
    const components = _.extend(this.state.components)
    components.KMMaterialWaiting.displayWaiting = false
    this.setState({components: components})
  }
  handleRegisterView (event) {
    this.setState({'viewMode': 'register'})
  }
  handleLoginView (event) {
    this.setState({'viewMode': 'login'})
  }
  handleResetPasswordView = (event) => this.setState({'viewMode': 'resetPassword'})
  
  
  componentDidMount () {
    if(this.props.history.location.pathname === '/reset/'){
      this.setState({
        token:this.props.history.location.search,
        'viewMode': 'resetPassword'
      })
    }
  }
  render () {
    const { classes, ...rest } = this.props
    return (
      <div className="LoginPage">
        <CookieConsent
        location="bottom"
        containerClasses="alert alert-warning col-lg-6"
        buttonText="j'accepte"
        style={{width:'50%'}}
        >
Utilisation des cookies.<br/>En poursuivant votre navigation, vous acceptez l'utilisation de cookies
</CookieConsent>
{/* <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-warning col-lg-12"
          contentClasses="text-capitalize"
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span>
        </CookieConsent> */}
        <Header
          absolute
          color="transparent"
          brandImage={this.props.client.kmClientLogo}
          brand=""
          rightLinks={<>
          
          <HeaderLinks noHeaderLinkLoginTitle={true}/>
            En cas de soucis de chargements ou d'interface, privilégiez <a href="https://www.google.com/intl/fr/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a>.
          </>}
          {...rest}
        >
        </Header>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          {this.state.viewMode === 'resetPassword' &&
          <ResetPasswordForm
            openPrivateRoute={this.props.openPrivateRoute}
            displayWaitingOn={this.displayWaitingOn}
            displayWaitingOff={this.displayWaitingOff}
            handleSwitchView={this.handleLoginView}
          />
          }
          {this.state.viewMode === 'register' &&
          <RegisterForm
            openPrivateRoute={this.props.openPrivateRoute}
            displayWaitingOn={this.displayWaitingOn}
            displayWaitingOff={this.displayWaitingOff}
            handleSwitchView={this.handleLoginView}
          />

          }
          {this.state.viewMode === 'login' &&
          <LoginForm
            openPrivateRoute={this.props.openPrivateRoute}
            displayWaitingOn={this.displayWaitingOn}
            displayWaitingOff={this.displayWaitingOff}
            handleSwitchView={this.handleRegisterView}
          />
          }

          <Footer whiteFont />
        </div>

        <KMMaterialSnackBar
          type="Snackbar"
          open={this.state.open}
          message={this.state.message}
          autoHideDuration={this.state.autoHideDuration}
          action={this.state.action}
          onClose={this.state.onClose}
        />

        <KMMaterialWaiting

          display={this.state.components.KMMaterialWaiting.displayWaiting}

        />
      </div>
    )
  }
}

export default withStyles(loginPageStyle)(withRouter(withClient(LoginPage)))
