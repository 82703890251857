import * as serviceWorker from './serviceWorker';
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

import { MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { KMUserProvider } from './stores/KMUserProvider'
import { KMResourceProvider } from './stores/KMResourceProvider'
import { KMMessageProvider } from './stores/KMMessageProvider'

import { KMProfilProvider } from './stores/KMProfilProvider'
import { KMProductProvider } from './stores/KMProductProvider'
import { KMClientProvider } from './stores/KMClientProvider'
import { KMEventProvider } from './stores/KMEventProvider'
import { KMLocationProvider } from './stores/KMLocationProvider'
import { KMEstablishmentProvider } from './stores/KMEstablishmentProvider'
import { KMAssociationProvider } from './stores/KMAssociationProvider'
import { KMScheduledBroadcastProvider } from './stores/KMScheduledBroadcastProvider'
import { KMReportProvider } from './stores/KMReportProvider'
import { KMFilterProvider } from './stores/KMFilterProvider'


import Dashboard from 'layouts/Dashboard/Dashboard.js'

import ReactDOM from 'react-dom'
import LoginPage from './views/LoginPage/LoginPage'
import React, { Component } from 'react'
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import config from 'config'
// import 'assets/scss/material-kit-react.css?v=1.3.0'
import 'assets/scss/material-kit-react.css'
import superagent from 'superagent'
import WorkInProgressView from './views/WorkInProgressView/WorkInProgressView'


const hist = createBrowserHistory()

const fetchIsAuthenticated = async () => {
  const oauthIsAuthenticatedUrl = config.oauth.isAuthenticated.url
  let apiResponseNetwork

  try {
    apiResponseNetwork = await superagent
      .get(oauthIsAuthenticatedUrl)
      .set('X-JWT', window.localStorage.getItem('JWT'))
      .withCredentials()

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') { return false }
    return (responseKeepapi.data.auth === true)
  } catch (error) { return false }
}
const oauth = {
  isAuthenticated: false,
  async authenticate(cb) {
    this.isAuthenticated = await fetchIsAuthenticated()
    if (this.isAuthenticated === true) {
      setTimeout(cb, 100) // fake async
    }
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true

  },
  palette: {
    //  primary:{
    // main: purple[400],
    //  },

    secondary: {

      main: purple[400],

    },

  },
  status: {
    danger: 'orange',
  },
});

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        oauth.isAuthenticated ? (
          <MuiThemeProvider theme={theme}>

            <KMMessageProvider><KMResourceProvider><KMEstablishmentProvider><KMLocationProvider><KMProfilProvider><KMProductProvider><KMEventProvider><KMClientProvider><KMUserProvider><KMAssociationProvider>
              <KMScheduledBroadcastProvider>
                <KMReportProvider>
                  <KMFilterProvider>
                    <Component {...props} />
                  </KMFilterProvider>
                </KMReportProvider>
              </KMScheduledBroadcastProvider>
            </KMAssociationProvider></KMUserProvider></KMClientProvider></KMEventProvider></KMProductProvider></KMProfilProvider></KMLocationProvider></KMEstablishmentProvider></KMResourceProvider></KMMessageProvider>
          </MuiThemeProvider>

        ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = { redirectToReferrer: false }
    this.openPrivateRoute = this.openPrivateRoute.bind(this)
  }
  componentDidMount() {
    this.openPrivateRoute()
  }
  openPrivateRoute() {
    let self = this
    oauth.authenticate(() => {
      self.setState({ redirectToReferrer: true })
    })
  }
  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } }
    let { redirectToReferrer } = this.state
    if (redirectToReferrer) return <Redirect to={from} />
    return (
      <KMClientProvider public><LoginPage openPrivateRoute={this.openPrivateRoute} /></KMClientProvider>
    )
  }
}
// ReactDOM.render(
//   <div>{JSON.stringify(process.env)}</div>
//   ,
//   document.getElementById('root')
// )
const maintenance = false
if (maintenance === false || window.localStorage.getItem('maintenance') === 'false') {

  ReactDOM.render(

    <SnackbarProvider maxSnack={3}>

      <Router history={hist}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/reset" component={Login} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </Router>
    </SnackbarProvider>
    ,
    document.getElementById('root')
  )

} else {
  ReactDOM.render(
    <WorkInProgressView />
    ,
    document.getElementById('root')
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
