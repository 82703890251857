import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom'
import React from 'react'
import lodash from 'lodash'

import config from 'config'
import Reflux from 'reflux'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { InputAdornment, IconButton } from '@material-ui/core'
// @material-ui/icons
import { EmailRounded, LockRounded, VisibilityRounded } from '@material-ui/icons'
// core components
import GridContainer from 'components/KMLogin/Grid/GridContainer.jsx'
import GridItem from 'components/KMLogin/Grid/GridItem.jsx'
import Button from 'components/KMLogin/CustomButtons/Button.jsx'
import Card from 'components/KMLogin/Card/Card.jsx'
import CardBody from 'components/KMLogin/Card/CardBody.jsx'
import CardHeader from 'components/KMLogin/Card/CardHeader.jsx'
import CardFooter from 'components/KMLogin/Card/CardFooter.jsx'
import KMCustomInput from 'components/KMLogin/KMCustomInput/KMCustomInput.jsx'

import registerFormStyle from 'assets/jss/material-kit-react/views/loginPage.jsx'

let dico = require('../../lang/front.fr.js')

class ResetPasswordForm extends Reflux.Component {
  constructor (props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      flagPasswordVisible: false,

      cardAnimaton: 'cardHidden',
      viewMode: 'login',
      components: {
        KMMaterialWaiting: {
          displayWaiting: false
        }
      },
      forms:
        {
          email: {
    				errorText: '',
    				errorTextMessage: dico.login.error.email,
    				pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    				hintText: dico.login.email,
            value: '',
            label: dico.login.email,
            id: 'email',
            success: false
          },
          password: {
    				errorText: '',
    				errorTextMessage: dico.login.error.password,
    				pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.+~{[|\\^\]}%!@#$%^&*;,_])(?=.{5,})/,
    				hintText: dico.login.password,
            value: '',
            label: dico.login.password,
            id: 'password',
            success: false
          },
          firstName: {
    				errorText: '',
    				errorTextMessage: dico.login.error.firstName,
    				pattern: /.{1,}/,
    				hintText: dico.login.firstName,
            value: '',
            label: dico.login.firstName,
            id: 'firstName',
            success: false
          },
          lastName: {
    				errorText: '',
    				errorTextMessage: dico.login.error.lastName,
    				pattern: /.{2,}/,
    				hintText: dico.login.lastName,
            value: '',
            label: dico.login.lastName,
            id: 'lastName',
            success: false
          },
          establishmentId: {
    				errorText: '',
    				errorTextMessage: dico.login.error.establishmentId,
    				pattern: null,
    				hintText: dico.login.establishmentId,
            value: '',
            label: dico.login.establishmentId,
            id: 'establishmentId',
            success: false
          }
        }
    }
  }
  handleFlagPasswordVisible = () => this.setState({ flagPasswordVisible: !this.state.flagPasswordVisible })

  handleInputChangeMaterial = (event) => {
    const target = event.target
    let id = event.target.id
	  let value = target.type === 'checkbox' ? target.checked : target.value
	  let forms = lodash.extend(this.state.forms)
	  // prepare value to set
    forms[id].value = value
    this.setState(prevState => ({
		   forms: forms
	    }))
  }
  handleSelectChangeMaterial = (name,value) => {
    let forms = lodash.extend(this.state.forms)
	  // prepare value to set
    forms[name].value = value
    this.setState(prevState => ({
		   forms: forms
	    }))
  }
  customCheckField (field) {
    let {errorText, error, name} = this.checkField([field])
    const forms = lodash.extend(this.state.forms)
    forms[name].errorText = errorText
    forms[name].success = !error
    this.setState({forms: forms})
    return !error
  }
  checkField (fields) {
    let error = false
    let errorText = ''
    let value = ''
    const forms = lodash.extend(this.state.forms)
    let name

    fields.map( (currentName) =>{
      if (!!error === true) { return currentName}
      name = currentName
      if (
        forms[currentName].hasOwnProperty('pattern') &&
        forms[currentName].hasOwnProperty('errorText') &&
        forms[currentName].hasOwnProperty('errorTextMessage') &&
				forms[currentName].errorTextMessage !== ''
      ) {
        value = forms[currentName].value
        if (!value.match(forms[currentName].pattern)) {
          error = true
          errorText = forms[currentName].errorTextMessage
        } else {
          errorText = ''
        }
      }
      return currentName
    })

    return {error, errorText, name}
  }

  handleResetPassword =async  (event) =>{
    this.props.displayWaitingOn()
    let error = true
    error = error & this.customCheckField('email')
    error = error & this.customCheckField('password')
    if (!!error !== false) {
      const oauthRegisterUrl = config.webhook.resetPasswordUser.url
      // let loggedIn = false
      // let message = ''
      
      try {
        let env = await fetch(oauthRegisterUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email: this.state.forms.email.value, password: this.state.forms.password.value, token: this.props.history.location.search.replace(/\?/gi,'')})
        })
        let {code} = await env.json()
        // message = data.message
        if (code === 'ok') {
          let message = `enregistrement réussi`
          let variant = 'success'
          this.props.enqueueSnackbar(message, { variant })
          setTimeout(()=>document.location.href='/?',2000)
           
        }
        else {
          let message = `une erreur est survenue X`+new Date()  
          let variant = 'warning'
          this.props.enqueueSnackbar(message, { variant })
          return
        }
      } catch (error) {
        let message = `une erreur est survenue X`+new Date()
          let variant = 'error'
          this.props.enqueueSnackbar(message, { variant })
          return
        // loggedIn = false
      }
    }
    this.props.displayWaitingOff()
  }

  componentDidMount () {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      450
    )
  }
  render () {
    const { classes } = this.props
    return (

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[this.state.cardAnimaton]}>
              <form className={classes.form}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault()
                    this.handleResetPassword()
                  }
                }}>

                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{dico.login.title1}</h4>
                  <div className={classes.socialLine}>
                    <Button
                      color="white"
                      onClick={this.props.handleSwitchView}
                    >
                      {dico.login.connectNow}&nbsp;&nbsp; <LockRounded fontSize="small" />
                    </Button>
                  </div>
                </CardHeader>
                <p className={classes.divider}>
                                  <b>Formulaire de réinitialisation de mot de passe</b>
                                  </p>
                <p className={classes.divider}>
                  
                  Les liens de réinitialisation du mot de passe expirent sous 24 heures. 
                  Ils expirent également 30 minutes après avoir cliqué sur le lien si vous n'avez pas réinitialisé votre mot de passe.
                  Si le lien ne fonctionne pas,
                  vous pouvez en demander <a onClick={this.props.handleSwitchView} >un autre ici.</a>
                  </p>
                <CardBody>
                 
                  
                  <KMCustomInput
                    labelText={(this.state.forms.email.errorText === '') ? this.state.forms.email.label : this.state.forms.email.errorText }
                    error={this.state.forms.email.errorText !== ''}
                    success={this.state.forms.email.success}
                    id={this.state.forms.email.id}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'email',
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailRounded className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <KMCustomInput
                    labelText={(this.state.forms.password.errorText === '') ? this.state.forms.password.label : this.state.forms.password.errorText }
                    error={this.state.forms.password.errorText !== ''}
                    success={this.state.forms.password.success}
                    handleMaterialChange={this.handleInputChangeMaterial}
                    id={this.state.forms.password.id}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: !!this.state.flagPasswordVisible === true ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end" onClick={this.handleFlagPasswordVisible} >
                          <IconButton style={{ padding: 0 }}>
                            {!!this.state.flagPasswordVisible === true ? <LockRounded /> : <VisibilityRounded />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.state.forms.password.errorText !== '' &&
                    <div>{dico.login.error.passwordStrength}</div>
                  }

                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" onClick={this.handleResetPassword}>
                    REINITIALISER VOTRE MOT DE PASSE
                  </Button>

                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

    )
  }
}

export default withStyles(registerFormStyle)(withRouter(withSnackbar(ResetPasswordForm)))
