import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMFilterContext = createContext({
  filterStore: {}, // store all the filter details
  updateFilterStore: () => { },
  updateFilterStoreAndRefresh: () => { },
  initFilterStore: () => { },
  reloadFilterStore: () => { },
  filterStoreRightPermissionDenied: true
})

export class KMFilterProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterStore: {
        fieldCriteriaActifs: [], // for mockup front remove thi sline when backend is ready pleaz!
        fieldCriteriaGroups: [], // for mockup front remove thi sline when backend is ready pleaz!
        fieldCriterias: [],// for mockup front remove thi sline when backend is ready pleaz!
        filterCriteriaActifs: [], // for mockup front remove thi sline when backend is ready pleaz!
        filterCriteriaGroups: [], // for mockup front remove thi sline when backend is ready pleaz!
        filterCriterias: [],// for mockup front remove thi sline when backend is ready pleaz!
      }, // store all the filter details
      updateFilterStore: this.updateFilterStore,
      updateFilterStoreAndRefresh: this.updateFilterStoreAndRefresh,
      initFilterStore: this.initFilterStore,
      reloadFilterStore: this.reloadFilterStore,
      filterStoreRightPermissionDenied: true
    }
    this.updateFilterStore = this.updateFilterStore.bind(this)
    this.updateFilterStoreAndRefresh = this.updateFilterStoreAndRefresh.bind(this)
    this.initFilterStore = this.initFilterStore.bind(this)
    this.reloadFilterStore = this.reloadFilterStore.bind(this)
  }
  reloadFilterStore = (key, value) => {
    this.initFilterStore(this.state.filterStore.filterId)
  }
  updateFilterStore = (key, value) => {
    let myFilterStore = this.state.filterStore // faster deep copy 
    myFilterStore[key] = value
    this.setState({ filterStore: myFilterStore })
    // let filterStore = { ...this.state.filterStore }
    // filterStore[key] = value
    // this.setState({ filterStore })
  }
  updateFilterStoreAndRefresh = (key, value) => {
    this.updateFilterStore(key, value)
    this.updateFilterStore('refreshToggle', !!!this.state.filterStore.refreshToggle)
  }
  initFilterStore = async (filterId) => {
    let apirUrl = config.filter.detail.url
    apirUrl = apirUrl.replace('___FILTERID___', filterId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let filterStore = responseKeepapi.data[0]
      this.setState({ filterStore, filterStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMFilterContext.Provider value={this.state}>
        {this.props.children}
      </KMFilterContext.Provider>
    )
  }
}

export const withFilterStore = Component => props => (
  <KMFilterContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMFilterContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withFilter } from 'stores/KMFilterProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.filter.firstName}!</h1>)
  }
}
export default withFilter(TOTO)

 */
