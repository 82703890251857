module.exports = {

  'login': {
    'title1': 'Déjà inscrit ?',
    'title2': 'Pas encore inscrit ?',
    'fillAllBlanks': 'Remplissez les champs suivants',
    'subscribeNow': 'inscrivez-vous ici',
    'connectNow': 'connectez-vous ici',
    'titleSocials': 'Ou connecter vous directement via',
    'socialsCgu': 'En vous connectant via votre compte de réseaux sociaux, vous déclarez accepter les conditions générales d"utilisations',
    'loginAction': 'Se connecter',
    'loginActionSSO': 'Se connecter SSO',
    'registerAction': `Faire une demande d'inscription`,
    'logout': 'Déconnexion',
    'loginOr': 'Login or',
    'or': 'ou',
    'signUp': 'Créer un compte',
    'rememberMe': 'Se souvenir de moi',
    'forgotPassword': 'Mot de passe oublié ?',
    'error': {
      'password': 'Le mot de passe est obligatoire',
      'passwordStrength': 'Le mot de passe doit contenir au moins 5 caractères avec au moins une minuscule + une majuscule + un chiffre + un caractère spécial (par exemple %!@#$&*-.+~{[|^]})',
      'firstName': 'Le prénom est obligatoire',
      'lastName': 'Le nom est obligatoire',
      'etablissementId': 'Veuillez choisir un établissement',
      'establishmentId': 'Veuillez choisir un établissement',
      'email': `L'email est obligatoire`,
      'oauth': {
        'access_denied': 'Vous avez refusez la connexion, donc nous ne pouvons pas vous identifier, veuillez accepter afin de vous connecter',
        'not_found': `Nous n'avons pas trouvé de correspondance dans notre base de donnée. Veuillez soit contacter l'administrateur si le problème persiste ou utilisez le lien de mot de passe oublié.`,
        'notRegister': `Nous n'avons pas réussi à créer votre fiche. Veuillez soit contacter l'administrateur si le problème persiste ou réessayer un peu plus tard.`
      }
    },
    firstName: `Votre prénom ...`,
    lastName: `Votre nom ...`,
    email: `Email ...`,
    password: `Mot de passe ...`,
    etablissementId: 'Votre établissement ...',
    establishmentId: 'Votre établissement/ votre service ...'
  },
  headerLinks: {
    logTitle: 'Bonjour, Identifiez-Vous'
  },
  KMEstablishmentLabel: {
    label: `Libellé principal court (utilisé dans les listes et les infos bulles)`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'establishmentLabel',
    focusKey: null,
    className: 'KMEstablishmentLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEstablishmentLabel',
    required: true
  },
  KMResourceLabel: {
    label: `Libellé du document`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'resourceLabel',
    focusKey: null,
    className: 'KMResourceLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMResourceLabel',
    required: true
  },
  KMScheduledBroadcastLabel: {
    label: `Libellé du message planifié`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'scheduledBroadcastLabel',
    focusKey: null,
    className: 'KMScheduledBroadcastLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMScheduledBroadcastLabel',
    required: true
  },
  KMMessageLabel: {
    label: `Libellé du message`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'messageLabel',
    focusKey: null,
    className: 'KMMessageLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMMessageLabel',
    required: true
  },
  KMEstablishmentFullLabel: {
    label: `Libellé`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'establishmentFullLabel',
    focusKey: null,
    className: 'KMEstablishmentFullLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEstablishmentFullLabel',
    required: true
  },
  KMEstablishmentLogo: {
    label: `Lien direct`,
    popUpLabel: `Logo de l'établissement`,

    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'establishmentLogo',
    className: 'KMEstablishmentLogo',
    id: 'KMEstablishmentLogo',
    required: false,
    docType: 'establishmentLogo',

  },
  KMMessageFile: {
    label: ``,
    popUpLabel: `Fichier lié au message`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'messageFile',
    className: 'KMMessageFile',
    id: 'KMMessageFile',
    required: false,
    docType: 'messageFile',
  },
  KMResourceFile: {
    label: ``,
    popUpLabel: `Fichier lié à la ressource`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'resourceFile',
    className: 'KMResourceFile',
    id: 'KMResourceFile',
    required: false,
    docType: 'resourceFile',
  },
  KMEstablishmentLogoSmall: {
    label: `Lien direct`,
    popUpLabel: `Logo de l'établissement 50 * 50`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'establishmentLogoSmall',
    className: 'KMEstablishmentLogoSmall',
    id: 'KMEstablishmentLogoSmall',
    required: false,
    docType: 'establishmentLogoSmall',

  },
  KMEventDescription: {
    label: `Description`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Le champ description est obligatoire`,
    placeholder: `ex : Vente de tickets pour festival, rencontres erasmus... Soyez le plus précis dans votre demande`,
    name: 'eventDescription',
    className: 'KmEventDescription',
    id: 'KMEventDescription',
    required: true
  },


  KMEventLabel: {
    label: `Libellé`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventLabel',
    focusKey: null,
    className: 'KMEventLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventLabel',
    required: true
  },
  KmEventMultiple: {
    label: `Lien direct`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'KmEventMultiple',
    className: 'KmEventMultiple',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventResponsibleUserName: {
    label: `nom`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `le nom du responsable`,
    name: 'KmEventResponsibleUserName',
    className: 'KmEventResponsibleUserName',
    id: 'KmEventTransportArrivalDatetime',
    required: false

  },
  KmEventResponsibleUserEmail: {
    label: `email`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `email`,
    name: 'KmEventResponsibleUserEmail',
    className: 'KmEventResponsibleUserEmail',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventResponsibleUserMobile: {
    label: `tél`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `+33 XX XX XX XX`,
    name: 'KmEventResponsibleUserMobile',
    className: 'KmEventResponsibleUserMobile',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventTransportDepartureLocation: {
    label: `Lieu de départ`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'KmEventTransportDepartureLocation',
    className: 'KmEventTransportDepartureLocation',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventTransportArrivalLocation: {
    label: `Lieu d'arrivée`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'KmEventTransportArrivalLocation',
    className: 'KmEventTransportArrivalLocation',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventTransportDepartureDatetime: {
    label: `horaire`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'KmEventTransportDepartureDatetime',
    className: 'KmEventTransportDepartureDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'dd/MM/YY HH:mm',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventTransportArrivalDatetime: {
    label: `horaire`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'KmEventTransportArrivalDatetime',
    className: 'KmEventTransportArrivalDatetime',
    format: 'ddd MMM YYYY hh:mm',
    id: 'KmEventTransportArrivalDatetime',
    required: false
  },
  KmEventType: {
    label: `Type d'événement`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'eventTypeId',
    className: 'KmEventType',
    format: '',
    id: 'KmEventType',
    required: false
  },
  KMMessageStartDatetime: {
    label: `Date et horaire de début`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'messageDatetimes',
    focusKey: 'messageDatetimeStart',
    className: 'KMMessageStartDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KMMessageStartDatetime',
    required: false
  },
  
  KMScheduledBroadcastEveryUnit: {
    label: `unités`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'scheduledBroadcastEveryUnit',
    focusKey: 'scheduledBroadcastEveryUnit',
    className: 'KMScheduledBroadcastEveryUnit',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMScheduledBroadcastEveryUnit',
    required: false
  },
  KMScheduledBroadcastWorkflowId: {
    label: `Flux associé`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'kmWorkflowId',
    focusKey: 'kmWorkflowId',
    className: 'KMScheduledBroadcastWorkflowId',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMScheduledBroadcastWorkflowId',
    required: false
  },
  KMResourceFlagHypervision: {
    label: `hide visibility to interal resource (hypervision field)`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'resourceFlagHypervision',
    focusKey: 'resourceFlagHypervision',
    className: 'KMResourceFlagHypervision',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMResourceFlagHypervision',
    required: false
  },
  
  KMScheduledBroadcastEveryAmount: {
    label: `tous les`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'scheduledBroadcastEveryAmount',
    focusKey: 'scheduledBroadcastEveryAmount',
    className: 'KMScheduledBroadcastEveryAmount',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMScheduledBroadcastEveryAmount',
    required: false
  },
  KMScheduledBroadcastStartDatetime: {
    label: `Commence à partir du`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'scheduledBroadcastStartDatetime',
    focusKey: 'scheduledBroadcastStartDatetime',
    className: 'KMScheduledBroadcastStartDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KMScheduledBroadcastStartDatetime',
    required: false
  },
  KMMessageEndDatetime: {
    label: `Date et horaire de fin`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'messageDatetimes',
    focusKey: 'messageDatetimeEnd',
    className: 'KMMessageEndDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KMMessageEndDatetime',
    required: false
  },
  KMMessageDetail: {
    label: `Détail du message`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'messageDetail',
    focusKey: null,
    className: 'KMMessageDetail',
    minutesStep: 15,
    format: null,
    formatMobile: null,
    id: 'KMMessageDetail',
    required: false
  },
  KmEventStartDatetime: {
    label: `Date et horaire de début`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'eventDatetimes',
    focusKey: 'eventDatetimeStart',
    className: 'KmEventStartDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KmEventStartDatetime',
    required: false
  },
  KmEventEndDatetime: {
    label: `Date et horaire de fin`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'eventDatetimes',
    focusKey: 'eventDatetimeEnd',
    className: 'KmEventEndDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KmEventEndDatetime',
    required: false
  },
  KMLocationStartDatetime: {
    label: `Date et horaire de début`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'locationDatetimes',
    focusKey: 'locationDatetimeStart',
    className: 'KMLocationStartDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KMLocationStartDatetime',
    required: false
  },
  KMLocationEndDatetime: {
    label: `Date et horaire de fin`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'locationDatetimes',
    focusKey: 'locationDatetimeEnd',
    className: 'KMLocationEndDatetime',
    minutesStep: 15,
    format: 'DD/MM/YYYY     à     HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'KMLocationEndDatetime',
    required: false
  },
  KMEventStudentsUserFirstName: {
    label: `Prénom`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventStudents',
    focusKey: 'userFirstName',
    className: 'KMEventStudentsUserFirstName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventStudentsUserFirstName',
    required: true
  },
  KMEventStudentsUserLastName: {
    label: `Nom`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventStudents',
    focusKey: 'userLastName',
    className: 'KMEventStudentsUserLastName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventStudentsUserLastName',
    required: true
  },
  KMEventStudentsUserEmail: {
    label: `Email`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventStudents',
    focusKey: 'userEmail',
    className: 'KMEventStudentsUserEmail',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventStudentsUserEmail',
    required: true
  },
  KMEventStudentsMobilePhone: {
    label: `Mobile`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: "Le numéro de téléphone est obligatoire",
    placeholder: ``,
    name: 'eventStudents',
    focusKey: 'userMobilePhone',
    className: 'KMEventStudentsMobilePhone',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventStudentsMobilePhone',
    required: true
  },
  KMEventStudentsUserGender: {
    label: `Civilité`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'eventStudents',
    focusKey: 'userGender',
    className: 'KMEventStudentsUserGender',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventStudentsUserGender',
    required: false
  },

  KMEventAreaId: {
    label: `Emplacements`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'locationIds',
    focusKey: null,
    className: 'KMEventAreaId',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventAreaId',
    required: true
  },
  KMEventAssociationId: {
    label: `Associations`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: `Filtre sur les noms des associations`,
    name: 'associationIds',
    focusKey: null,
    className: 'KMEventAssociationId',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventAssociationId',
    required: false
  },
  KMAssociationUserFirstName: {
    label: `Prénom`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'associationUsers',
    focusKey: 'userFirstName',
    className: 'KMAssociationUserFirstName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationUserFirstName',
    required: true
  },
  KMAssociationUserLastName: {
    label: `Nom du président`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'associationUsers',
    focusKey: 'userLastName',
    className: 'KMAssociationUserLastName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationUserLastName',
    required: true
  },
  KMAssociationUserEmail: {
    label: `Email du président`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'associationUsers',
    focusKey: 'userEmail',
    className: 'KMAssociationUserEmail',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationUserEmail',
    required: true
  },
  KMAssociationUserMobilePhone: {
    label: `Mobile`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: "Le numéro de téléphone est obligatoire",
    placeholder: ``,
    name: 'associationUsers',
    focusKey: 'userMobilePhone',
    className: 'KMAssociationMobilePhone',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationMobilePhone',
    required: true
  },
  KMAssociationUserGender: {
    label: `Civilité`,
    hintText: "",
    pattern: /m|mme/i,
    errorTextMessage: "",
    placeholder: ``,
    name: 'associationUsers',
    focusKey: 'userGender',
    className: 'KMAssociationUserGender',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationUserGender',
    required: false
  },
  KMAssociationAddressFull: {
    label: `Adresse de l'association`,
    hintText: "",
    pattern: /.{1,}/,
    errorTextMessage: "Le champ adresse est obligatoire",
    placeholder: ``,
    name: 'addressFull',
    focusKey: '',
    className: 'KMAssociationAddressFull',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationAddressFull',
    required: true
  },
  KMAssociationLabel: {
    label: `Nom de l'association`,
    hintText: "",
    pattern: /.{1,}/,
    errorTextMessage: `Le nom de l'asso est obligatoire`,
    placeholder: ``,
    name: 'associationLabel',
    focusKey: '',
    className: 'KMAssociationLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationLabel',
    required: true
  },
  KMAssociationLocationIdEtablissement: {
    label: `Rattachement de l'association à l'établissement`,
    hintText: "",
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'locationIdEtablissement',
    focusKey: '',
    className: 'KMAssociationLocationIdEtablissement',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationLocationIdEtablissement',
    required: false
  },
  // KMAssociationEstablishmentId: {
  //   label: `Rattachement de l'association à l'établissement`,
  //   hintText: "",
  //   pattern: /.{1,}/,
  //   errorTextMessage: `Ce champ est obligatoire`,
  //   placeholder: ``,
  //   name: 'locationIdEtablissement',
  //   focusKey: '',
  //   className: 'KMAssociationEstablishmentId',
  //   minutesStep: null,
  //   format: null,
  //   formatMobile: null,
  //   id: 'KMAssociationEstablishmentId',
  //   required: false
  // },
  KMAssociationEstablishmentId: {
    label: `Rattachement de l'association à l'établissement`,
    hintText: "",
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'establishmentId',
    focusKey: '',
    className: 'KMAssociationEstablishmentId',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMAssociationEstablishmentId',
    required: false
  },
  KMEventHelpStationUsersFirstName: {
    label: `Société`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventHelpStationUsers',
    focusKey: 'userFirstName',
    className: 'KMEventHelpStationUsersFirstName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventHelpStationUsersFirstName',
    required: true
  },
  KMEventHelpStationUsersLastName: {
    label: `Nom & Prénom du référent`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventHelpStationUsers',
    focusKey: 'userLastName',
    className: 'KMEventHelpStationUsersLastName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventHelpStationUsersLastName',
    required: true
  },
  KMEventHelpStationUsersEmail: {
    label: `Email`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventHelpStationUsers',
    focusKey: 'userEmail',
    className: 'KMEventHelpStationUsersEmail',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventHelpStationUsersEmail',
    required: true
  },
  KMEventHelpStationMobileUsersPhone: {
    label: `Mobile`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: "Le numéro de téléphone est obligatoire",
    placeholder: ``,
    name: 'eventHelpStationUsers',
    focusKey: 'userMobilePhone',
    className: 'KMEventHelpStationMobileUsersPhone',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventHelpStationMobileUsersPhone',
    required: true
  },
  KMEventHelpStationUsersGender: {
    label: `Civilité`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'eventHelpStationUsers',
    focusKey: 'userGender',
    className: 'KMEventHelpStationUsersGender',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'KMEventHelpStationUsersGender',
    required: false
  },

  eventSecurityUserFirstName: {
    label: `Société`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventSecurityUsers',
    focusKey: 'userFirstName',
    className: 'eventSecurityUserFirstName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventSecurityUserFirstName',
    required: true
  },
  eventSecurityUserLastName: {
    label: `Nom & Prénom du référent`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventSecurityUsers',
    focusKey: 'userLastName',
    className: 'eventSecurityUserLastName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventSecurityUserLastName',
    required: true
  },
  eventSecurityUserEmail: {
    label: `Email`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventSecurityUsers',
    focusKey: 'userEmail',
    className: 'eventSecurityUserEmail',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventSecurityUserEmail',
    required: true
  },
  eventSecurityUserPhone: {
    label: `Mobile`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: "Le numéro de téléphone est obligatoire",
    placeholder: ``,
    name: 'eventSecurityUsers',
    focusKey: 'userMobilePhone',
    className: 'eventSecurityUserPhone',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventSecurityUserPhone',
    required: true
  },
  eventSecurityUserGender: {
    label: `Civilité`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'eventSecurityUsers',
    focusKey: 'userGender',
    className: 'eventSecurityUserGender',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventSecurityUserGender',
    required: false
  },

  eventSecurityDoc: {
    label: `Justificatifs`,
    popUpLabel: `Contrat, déclarations etc...`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'eventSecurityDoc',
    docType: 'eventSecurityDoc',
    className: 'eventSecurityDoc',
    id: 'eventSecurityDoc',
    required: false
  },
  KMEventImg: {
    label: `Lien direct`,
    popUpLabel: `Image / flyer de la manifestation`,

    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'eventImg',
    className: 'KMEventImg',
    id: 'KMEventImg',
    required: false,
    docType: 'eventImg',

  },

  eventHelpStationDoc: {
    label: `Justificatifs`,
    popUpLabel: `Contrat, déclarations etc...`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'eventHelpStationDoc',
    docType: 'eventHelpStationDoc',
    className: 'eventHelpStationDoc',
    id: 'eventHelpStationDoc',
    required: false

  },
  eventMemberShipDeclaration: {
    label: `Justificatifs`,
    popUpLabel: `Déclaration d'effectif, justificatifs du propriétaire d'établissement`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'eventMemberShipDeclaration',
    docType: 'eventMemberShipDeclaration',
    className: 'eventMemberShipDeclaration',
    id: 'eventMemberShipDeclaration',
    required: true

  },


  userFirstName: {
    label: `Prénom`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'userFirstName',
    focusKey: null,
    className: 'userFirstName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userFirstName',
    required: true
  },


  userLastName: {
    label: `Nom`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'userLastName',
    focusKey: null,
    className: 'userLastName',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userLastName',
    required: true
  },

  userEmail: {
    label: `Email`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'userEmail',
    focusKey: null,
    className: 'userEmail',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userEmail',
    required: true
  },

  userMobilePhone: {
    label: `Portable`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: "",
    placeholder: ``,
    name: 'userMobilePhone',
    focusKey: null,
    className: 'userMobilePhone',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userMobilePhone',
    required: false
  },
  userAddressFull: {
    label: `Adresse`,
    hintText: "",
    pattern: null,
    errorTextMessage: "Le champ adresse est obligatoire",
    placeholder: ``,
    name: 'addressFull',
    focusKey: '',
    className: 'userAddressFull',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userAddressFull',
    required: false
  },
  userTitle: {
    label: `Titre`,
    hintText: ``,
    pattern: null,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'userTitle',
    focusKey: null,
    className: 'userTitle',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userTitle',
    required: false
  },
  userAvatarDoc: {
    label: `Avatar`,
    popUpLabel: ``,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'userAvatarDoc',
    docType: 'userAvatarDoc',
    className: 'userAvatarDoc',
    id: 'userAvatarDoc',
    required: true

  },
  
  userEstablishmentIds: {
    label: `Rattachement établissement`,
    hintText: "",
    pattern: null,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'establishmentIds',
    focusKey: '',
    className: 'userEstablishmentIds',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userEstablishmentIds',
    required: false
  },
  userPoleIds: {
    label: `Rattachement Pôle`,
    hintText: "",
    pattern: null,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'poleIds',
    focusKey: '',
    className: 'userPoleIds',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'userPoleIds',
    required: false
  },
  eventTransportsAddressFullFrom: {
    label: `Adresse de départ`,
    hintText: "",
    pattern: null,
    errorTextMessage: "Le champ adresse est obligatoire",
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'addressFullFrom',
    className: 'eventTransportsAddressFullFrom',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventTransportsAddressFullFrom',
    required: true
  },
  eventTransportsAddressFullTo: {
    label: `Adresse d'arrivée`,
    hintText: "",
    pattern: null,
    errorTextMessage: "Le champ adresse est obligatoire",
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'addressFullTo',
    className: 'eventTransportsAddressFullTo',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventTransportsAddressFullTo',
    required: true
  },
  eventTransportsStartDatetime: {
    label: `Départ à`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'eventTransportStartDatetime',
    className: 'eventTransportsStartDatetime',
    minutesStep: 15,
    format: 'DD/MM/YY HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'eventTransportsStartDatetime',
    required: true
  },
  eventTransportsEndDatetime: {
    label: `Arrivée à`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'eventTransportEndDatetime',
    className: 'eventTransportsEndDatetime',
    minutesStep: 15,
    format: 'DD/MM/YY HH:mm',
    formatMobile: 'DD/MM/YY HH:mm',
    id: 'eventTransportsEndDatetime',
    required: true
  },
  eventTransportsUserSocietyLabel: {
    label: `Nom de la compagnie`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'userSocietyLabel',
    className: 'eventTransportsUserSocietyLabel',
    minutesStep: 15,
    format: '',
    formatMobile: '',
    id: 'eventTransportsUserSocietyLabel',
    required: true
  },
 
  eventTransportsUserFirstName: {
    label: `Nom & Prénom du référent`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'userFirstName',
    className: 'eventTransportsUserFirstName',
    minutesStep: 15,
    format: '',
    formatMobile: '',
    id: 'eventTransportsUserFirstName',
    required: true
  },
  eventTransportsUserEmail: {
    label: `Email du référent`,
    hintText: ``,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'userEmail',
    className: 'eventTransportsUserEmail',
    minutesStep: 15,
    format: '',
    formatMobile: '',
    id: 'eventTransportsUserEmail',
    required: true
  },
  eventTransportsUserMobilePhone: {
    label: `Mobile`,
    hintText: "",
    pattern: /\+{0,1}[0-9 ]{9,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'userMobilePhone',
    className: 'eventTransportsUserEmail',
    minutesStep: 15,
    format: '',
    formatMobile: '',
    id: 'eventTransportsUserEmail',
    required: true
  },
  eventTransportsType: {
    label: `Sens`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'eventTransportType',
    className: 'eventTransportsType',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventTransportsType',
    required: false
  },
  eventTransportsModeOfTransport: {
    label: `Type`,
    hintText: "",
    pattern: null,
    errorTextMessage: "",
    placeholder: ``,
    name: 'eventTransports',
    focusKey: 'eventTransportModeOfTransport',
    className: 'eventTransportsType',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'eventTransportsType',
    required: false
  },
  eventTransportsAttachment: {
    label: `Contrat passé avec le prestataire`,
    popUpLabel: `Contrat passé avec le prestataire`,
    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'eventTransportsAttachment',
    focusKey: 'eventTransportModeOfTransport',

    docType: 'eventTransportsAttachment',
    className: 'eventTransportsAttachment',
    id: 'eventTransportsAttachment',
    required: true

  },
  productLabel: {
    label: `Libellé principal court ( utilisé dans les listes et les infos bulles)`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'productLabel',
    focusKey: null,
    className: 'productLabel',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'productLabel',
    required: true
  },
  productStockInitial: {
    label: `Stock initial`,
    hintText: ``,
    pattern: /.{1,}/,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'productStockInitial',
    focusKey: null,
    className: 'productStockInitial',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'productStockInitial',
    required: true
  },

  productPoleId: {
    label: `Rattachement Pôle`,
    hintText: "",
    pattern: null,
    errorTextMessage: `Ce champ est obligatoire`,
    placeholder: ``,
    name: 'poleId',
    focusKey: '',
    className: 'productPoleId',
    minutesStep: null,
    format: null,
    formatMobile: null,
    id: 'productPoleId',
    required: false
  },
  productImage: {
    label: `Lien direct`,
    popUpLabel: `image de l'article`,

    hintText: ``,
    pattern: null,
    errorTextMessage: ``,
    placeholder: `http//:...`,
    name: 'productImage',
    className: 'productImage',
    id: 'productImage',
    required: false,
    docType: 'product',

  },

}

