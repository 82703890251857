import React, { createContext, Component } from 'react'
import config from 'config'

import superagent from 'superagent'

export const KMClientContext = createContext({
  client: {} // store all the client details
})

export class KMClientProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      public: false,
      client: {} // store all the client details
    }
  }
  componentDidMount() {
    this.fetchClient()
  }
  async fetchClient() {
    let apiClientUrl = this.props.public === true ? config.api.clientPublic.url : config.api.client.url
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiClientUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (error) { return }
    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') return
    this.setState({ client: responseKeepapi.data[0] })
  }

  render() {
    return (
      <KMClientContext.Provider value={this.state}>
        {this.props.children}
      </KMClientContext.Provider>
    )
  }
}

export const withClient = Component => props => (
  <KMClientContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMClientContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withClient } from './components/KMClientProvider/KMClientProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.client.firstName}!</h1>)
  }
}
export default withClient(TOTO)

 */
