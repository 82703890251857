require(`dotenv`).config()
export default  {
  version: process.env.REACT_APP_VERSION,
  ribbonMessage: process.env.REACT_APP_RIBBON_MESSAGE,
  SOCKET_URL: process.env.SOCKET_URL,
  mainDomainName: process.env.REACT_APP_DOMAIN_NAME,
  dashboard: {
    dashboardTodayEvent: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardTodayEvent`,
    },
    dashboardNextEvent: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardNextEvent`,
    },
    dashboardNextEventToTreat: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardNextEventToTreat`,
    },
    userAwaitingForAccreditation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/userAwaitingForAccreditation`,
    },

  },
  setting: {
    getSetting: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/setting/getSetting`,
    }
  },
  report: {
    list: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/report`
    },
    detail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/report/___REPORTID___/detail`
    },
    query: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/report/___REPORTID___/query`
    },
  },
  filter: {
    detail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/filter/___FILTERID___/detail`
    },
  },
  stats: {
    archivageSession: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/archivageSession`,
    },
    sessionDailyGroupByProfil: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/sessionDailyGroupByProfil`,
    },
    sessionStatsNbActif: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/sessionStatsNbActif`,
    },
    mailStatsMonthly: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/mailStatsMonthly`,
    },

    eventStatsNbValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsNbValidate`,
    },
    eventStatsNbPending: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsNbPending`,
    },
    eventStatsPendingBeginInLessThanXDays: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsPendingBeginInLessThanXDays`,
    },
    userStatsNbActif: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsNbActif`,
    },
    userStatsNbAll: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsNbAll`,
    },
    eventStatsDailyValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsDailyValidate`,
    },
    eventStatsDailyValidateLastWeek: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsDailyValidateLastWeek`,
    },
    eventStatsYearlyValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsYearlyValidate`,
    },
    userStatsYearlyActifAndNoActif: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsYearlyActifAndNoActif`,
    },
    stockageStatsWeight: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/stockageStatsWeight`,
    },





  },
  webhook: {
    messagePlateformRead:{
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/message/read`,

    },
    updateEventFlagPublishInWeb: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/updateEventFlagPublishInWeb`,
    },
    anonymizeUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/anonymize`,
    },
    deleteDoc: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/delete`,
    },
    activateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/activateUser`,
    },
    desactivateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/desactivateUser`,
    },
    archiveEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/archive`,
    },
    cancelEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/cancel`,
    },
    deleteEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/delete`,
    },
    
    duplicateEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/duplicate`,
    },
    expirePasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/expirePassword`,
    },
    forgotPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/forgotPassword`,
    },
    resetPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/resetPassword`,
    },

    updateEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/update`,
    },
    updateProduct: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/product/update`,
    },
    archiveProduct: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/product/archive`,
    },
    updateLocation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/location/update`,
    },
    archiveLocation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/location/archive`,
    },
    updateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/update`,
    },

    uploadImg: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/upload`,
    },
    addEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/add`,
    },
    archiveEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/archive`,
    },
    updateEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/update`,
    },
    addEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/add`,
    },
    addUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/add`,
    },
    updateEventValidationLevel: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/update/validationLevel`,
    },
    updateEventComment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/update/comment`,
    },
    addResource: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/resource/add`,
    },
    updateResource: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/resource/update`,
    },  
    archiveResource: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/resource/archive`,
    },
    addScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/add`,
    },
    activateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/activate`,
    },
    desactivateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/desactivate`,
    },
    addProduct: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/product/add`,
    },
    updateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/update`,
    },
    archiveScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/archive`,
    },
    unArchiveScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/unArchive`,
    },
    addMessage: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/message/add`,
    },
    updateMessage: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/message/update`,
    },
    archiveMessage: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/message/archive`,
    },
    addAssociation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/association/add`,
    },
    addLocation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/location/add`,
    },
    updateAssociation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/association/update`,
    },
    archiveAssociation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/association/archive`,
    },
    archiveAssociation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/association/archive`,
    },
  },
  api: {
    mapTodayEvent:{
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/map/mapTodayEvent`
    },
    pdfEvent: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/pdf/event/___EVENTID___`
    },
    associationDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/association/___ASSOCIATIONID___/detail`
    },
    area: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/area`
    },
    client: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/session/client`
    },
    clientPublic: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/front/client`
    },
    establishment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/establishment`
    },
    establishmentPictures: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/establishmentPictures`
    },
    etablissement: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/etablissement`
    },
    establishmentDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/establishment/___ESTABLISHMENTID___/detail`
    },
    event: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event`
    },
    eventDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event/___EVENTID___/detail`
    },
    eventNotifyUserComment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event/___EVENTID___/notifyUserComment`
    },
    eventAssociation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event/___EVENTID___/association`
    },
    acl: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/kmAcl`
    },
    association: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/association`
    },
    location: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/location`
    },
    locationSite: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/locationSite`
    },
    locationPlan: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/locationPlan`
    },
    locationArea: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/locationArea`
    },
    locationDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/location/___LOCATIONID___/detail`
    },
    message: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/message`
    },
    messagePlateform: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/message/plateform`
    },
    messagePlateformUnread: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/message/plateform?unRead=1`
    },
    messageDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/message/___MESSAGEID___/detail`
    },
    segment : {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/segment`
    },
    notification: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/notification`
    },
    pole: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/pole`
    },
    product: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/product`
    },
    productDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/product/___PRODUCTID___/detail`
    },
    productLocation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/productLocation`
    },
    productStockByLocationAndDate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/product/stockByLocationAndDate`
    },
    profilDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/profil/___PROFILID___/detail`
    },
    

    resource: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/resource`
    },
    resourceDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/resource/___RESOURCEID___/detail`
    },
    scheduledBroadcast: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/scheduledBroadcast`
    },
    scheduledBroadcastDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/scheduledBroadcast/___SCHEDULEDBROADCASTID___/detail`
    },
    search: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/search`
    },
    userSession: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/session/user`
    },
    userRightManagement: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/userRightManagement`
    },
    userActivate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/user`
    },
    eventType: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/eventType`
    }
  },
  front: {
    establishment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/front/establishment`
    }
  },
  oauth: {
    logout: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/logout`
    },
    login: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/login`
    },
    loginSSO: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/loginSSO`
    },
    register: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/register`
    },
    isAuthenticated: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/isAuthenticated`
    }
  }
}
